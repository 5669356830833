<template>
  <div>
    <div class="is-flex is-align-items-center has-gap-6">
      <div class="is-flex is-align-items-center has-gap-2">
        <div>
          <b-dropdown aria-role="list" v-model="selectedYear">
            <template #trigger="{ active }">
              <b-button
                :label="'Év: ' + selectedYear"
                type="is-info"
                :icon-right="active ? 'chevron-up' : 'chevron-down'"
                :loading="loading"
              />
            </template>

            <b-dropdown-item
              v-for="year in years"
              :key="year"
              :value="year"
              aria-role="listitem"
              >{{ year }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="is-flex is-align-items-center has-gap-2">
        <b-checkbox-button
          v-model="includedTypes"
          native-value="FIX"
          :disabled="loading"
        >
          <span>{{ $tf("supportProjectPage.type.fix|Fix árazású") }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="includedTypes"
          native-value="LOCKUP"
          :disabled="loading"
        >
          <span>{{ $tf("supportProjectPage.type.lockup|Lekötés") }}</span>
        </b-checkbox-button>

        <b-checkbox-button
          v-model="includedTypes"
          native-value="BILLABLE"
          :disabled="loading"
        >
          {{ $tf("supportProjectPage.type.billable|Elszámolható") }}
        </b-checkbox-button>
      </div>
    </div>
    <h3 class="subtitle">
      {{ $tf("supportProjectPage.overview.title|Áttekintés") }}
    </h3>

    <loading-skeleton v-if="loading"></loading-skeleton>
    <template v-else>
      <div style="overflow-x: auto; padding-bottom: 24px">
        <table class="support-status-table">
          <tr class="table-header-row">
            <th></th>
            <th
              style="border-radius: 8px 0 0 8px"
              class="table-header-month-cell"
            >
              {{
                $tf("supportProjectPage.overview.table.header.yearToDate|YTD")
              }}
            </th>
            <th
              style="border-radius: 0 8px 8px 0"
              class="table-header-month-cell"
            >
              {{
                $tf(
                  "supportProjectPage.overview.table.header.entireYear|Teljes év"
                )
              }}
            </th>
            <th style="width: 16px"></th>
            <th class="table-header-month-cell" v-for="i in 12" :key="'a' + i">
              {{
                $tf(SHORT_MONTH_NAMES[i - 1])
                  .charAt(0)
                  .toUpperCase() + $tf(SHORT_MONTH_NAMES[i - 1]).slice(1)
              }}
            </th>
          </tr>
          <tr>
            <td></td>
            <td>
              {{ $tf(SHORT_MONTH_NAMES[0]) }}-{{
                $tf(SHORT_MONTH_NAMES[now.getMonth()])
              }}
            </td>
            <td>FC</td>
            <td></td>
            <td v-for="i in greenBubblesCount" :key="'b' + i">
              <div class="month-type-bubble bubble-green">
                {{ $tf("supportProjectPage.overview.table.bubble.fact|Tény") }}
              </div>
            </td>
            <td v-if="selectedYear === now.getFullYear()">
              <div class="month-type-bubble bubble-red">
                {{
                  $tf(
                    "supportProjectPage.overview.table.bubble.inProgress|Folyamatban"
                  )
                }}
              </div>
            </td>
            <td v-for="i in blueBubblesCount" :key="'c' + i">
              <div class="month-type-bubble bubble-blue">
                {{
                  $tf("supportProjectPage.overview.table.bubble.forecast|FC")
                }}
              </div>
            </td>
          </tr>
          <template v-if="!projectDetails.internal"
            ><tr class="table-section-row first-row">
              <td class="blue-cell">
                <div class="columns">
                  <div class="column">
                    {{
                      $tf(
                        "supportProjectPage.overview.table.row.income|Bevétel összesen"
                      )
                    }}
                  </div>
                  <div
                    class="column pl-0 my-auto is-narrow"
                    v-if="hasAnyActivity"
                  >
                    <a
                      @click="incomeExpanded = !incomeExpanded"
                      class="expand-toggle"
                      ><b-icon
                        :icon="incomeExpanded ? 'chevron-up' : 'chevron-down'"
                      ></b-icon>
                    </a>
                  </div>
                </div>
              </td>
              <td>{{ moneyify(projectDetails.ytdSummary?.all?.income) }}</td>
              <td>{{ moneyify(projectDetails.yearlySummary?.all?.income) }}</td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'is' + i">
                {{ moneyify(monthlySummary(i)?.income) }}
              </td>
            </tr>
            <tbody v-if="incomeExpanded">
              <tr class="table-section-row" v-if="hasFixes">
                <td>
                  {{
                    $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                  }}
                </td>
                <td>{{ moneyify(projectDetails.ytdSummary?.fix?.income) }}</td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.fix?.income) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'if' + i">
                  {{ moneyify(monthlyFixSummary(i)?.income) }}
                </td>
              </tr>
              <tr class="table-section-row" v-if="hasLockups">
                <td>
                  {{
                    $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                  }}
                </td>
                <td>
                  {{ moneyify(projectDetails.ytdSummary?.lockup?.income) }}
                </td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.lockup?.income) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'il' + i">
                  {{ moneyify(monthlyLockupSummary(i)?.income) }}
                </td>
              </tr>
              <tr class="table-section-row" v-if="hasBillables">
                <td>
                  {{
                    $tf(
                      "supportProjectPage.overview.table.row.billable|Elszámolható feladatok"
                    )
                  }}
                </td>
                <td>
                  {{ moneyify(projectDetails.ytdSummary?.billable?.income) }}
                </td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.billable?.income) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'ib' + i">
                  {{ moneyify(monthlyBillableSummary(i)?.income) }}
                </td>
              </tr>
            </tbody>
          </template>
          <tr
            class="table-section-row"
            :class="{ 'first-row': projectDetails.internal }"
          >
            <td class="blue-cell">
              <div class="columns">
                <div class="column">
                  {{
                    $tf(
                      "supportProjectPage.overview.table.row.budget|Büdzsé összesen"
                    )
                  }}
                </div>
                <div
                  class="column pl-0 my-auto is-narrow"
                  v-if="hasAnyActivity"
                >
                  <a
                    @click="budgetExpanded = !budgetExpanded"
                    class="expand-toggle"
                    ><b-icon
                      :icon="budgetExpanded ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </a>
                </div>
              </div>
            </td>
            <td>{{ moneyify(projectDetails.ytdSummary?.all?.budget) }}</td>
            <td>{{ moneyify(projectDetails.yearlySummary?.all?.budget) }}</td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'bs' + i">
              {{ moneyify(monthlySummary(i)?.budget) }}
            </td>
          </tr>
          <tbody v-if="budgetExpanded">
            <tr class="table-section-row" v-if="hasFixes">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                }}
              </td>

              <td>{{ moneyify(projectDetails.ytdSummary?.fix?.budget) }}</td>
              <td>{{ moneyify(projectDetails.yearlySummary?.fix?.budget) }}</td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'bf' + i">
                {{ moneyify(monthlyFixSummary(i)?.budget) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasLockups">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                }}
              </td>
              <td>{{ moneyify(projectDetails.ytdSummary?.lockup?.budget) }}</td>
              <td>
                {{ moneyify(projectDetails.yearlySummary?.lockup?.budget) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'bl' + i">
                {{ moneyify(monthlyLockupSummary(i)?.budget) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasBillables">
              <td>
                {{
                  $tf(
                    "supportProjectPage.overview.table.row.billable|Elszámolható feladatok"
                  )
                }}
              </td>
              <td>
                {{ moneyify(projectDetails.ytdSummary?.billable?.budget) }}
              </td>
              <td>
                {{ moneyify(projectDetails.yearlySummary?.billable?.budget) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'bb' + i">
                {{ moneyify(monthlyBillableSummary(i)?.budget) }}
              </td>
            </tr>
          </tbody>
          <tr class="table-section-row">
            <td class="blue-cell">
              <div class="columns">
                <div class="column">
                  {{
                    $tf(
                      "supportProjectPage.overview.table.row.spent|Költés összesen"
                    )
                  }}
                </div>
                <div
                  class="column pl-0 my-auto is-narrow"
                  v-if="hasAnyActivity"
                >
                  <a @click="costExpanded = !costExpanded" class="expand-toggle"
                    ><b-icon
                      :icon="costExpanded ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </a>
                </div>
              </div>
            </td>
            <td>{{ moneyify(projectDetails.ytdSummary?.all?.totalSpent) }}</td>
            <td>
              {{ moneyify(projectDetails.yearlySummary?.all?.totalSpent) }}
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'cs' + i">
              {{ moneyify(monthlySummary(i)?.totalSpent) }}
            </td>
          </tr>
          <tbody v-if="costExpanded">
            <tr class="table-section-row" v-if="hasFixes">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                }}
              </td>
              <td>
                {{ moneyify(projectDetails.ytdSummary?.fix?.totalSpent) }}
              </td>
              <td>
                {{ moneyify(projectDetails.yearlySummary?.fix?.totalSpent) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'cf' + i">
                {{ moneyify(monthlyFixSummary(i)?.totalSpent) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasLockups">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                }}
              </td>
              <td>
                {{ moneyify(projectDetails.ytdSummary?.lockup?.totalSpent) }}
              </td>
              <td>
                {{ moneyify(projectDetails.yearlySummary?.lockup?.totalSpent) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'cl' + i">
                {{ moneyify(monthlyLockupSummary(i)?.totalSpent) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasBillables">
              <td>
                {{
                  $tf(
                    "supportProjectPage.overview.table.row.billable|Elszámolható feladatok"
                  )
                }}
              </td>
              <td>
                {{ moneyify(projectDetails.ytdSummary?.billable?.totalSpent) }}
              </td>
              <td>
                {{
                  moneyify(projectDetails.yearlySummary?.billable?.totalSpent)
                }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'cb' + i">
                {{ moneyify(monthlyBillableSummary(i)?.totalSpent) }}
              </td>
            </tr>
          </tbody>
          <tr class="table-section-row">
            <td class="blue-cell has-text-left">
              <div class="columns">
                <div class="column">
                  {{
                    $tf(
                      "supportProjectPage.overview.table.row.costRatio|Költés arány"
                    )
                  }}
                </div>
                <div
                  class="column pl-0 my-auto is-narrow"
                  v-if="hasAnyActivity"
                >
                  <a
                    @click="costRatioExpanded = !costRatioExpanded"
                    class="expand-toggle"
                    ><b-icon
                      :icon="costRatioExpanded ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </a>
                </div>
              </div>
            </td>
            <td>
              {{ percentify(projectDetails.ytdSummary?.all?.costBudgetRatio) }}
            </td>
            <td>
              {{
                percentify(projectDetails.yearlySummary?.all?.costBudgetRatio)
              }}
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'cp' + i">
              {{ percentify(monthlySummary(i)?.costBudgetRatio) }}
            </td>
          </tr>
          <tbody v-if="costRatioExpanded">
            <tr class="table-section-row" v-if="hasFixes">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                }}
              </td>
              <td>
                {{
                  percentify(projectDetails.ytdSummary?.fix?.costBudgetRatio)
                }}
              </td>
              <td>
                {{
                  percentify(projectDetails.yearlySummary?.fix?.costBudgetRatio)
                }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'crf' + i">
                {{ percentify(monthlyFixSummary(i)?.costBudgetRatio) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasLockups">
              <td>
                {{
                  $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                }}
              </td>
              <td>
                {{
                  percentify(projectDetails.ytdSummary?.lockup?.costBudgetRatio)
                }}
              </td>
              <td>
                {{
                  percentify(
                    projectDetails.yearlySummary?.lockup?.costBudgetRatio
                  )
                }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'crl' + i">
                {{ percentify(monthlyLockupSummary(i)?.costBudgetRatio) }}
              </td>
            </tr>
            <tr class="table-section-row" v-if="hasBillables">
              <td>
                {{
                  $tf(
                    "supportProjectPage.overview.table.row.billable|Elszámolható feladatok"
                  )
                }}
              </td>
              <td>
                {{
                  percentify(
                    projectDetails.ytdSummary?.billable?.costBudgetRatio
                  )
                }}
              </td>
              <td>
                {{
                  percentify(
                    projectDetails.yearlySummary?.billable?.costBudgetRatio
                  )
                }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'crb' + i">
                {{ percentify(monthlyBillableSummary(i)?.costBudgetRatio) }}
              </td>
            </tr>
          </tbody>
          <tr class="table-section-row">
            <td class="blue-cell has-text-left">
              {{
                $tf(
                  "supportProjectPage.overview.table.row.timeRatio|Eltelt idő"
                )
              }}
            </td>
            <td>
              {{ percentify(projectDetails.ytdSummary?.all?.timeRatio) }}
            </td>
            <td>
              {{ percentify(projectDetails.yearlySummary?.all?.timeRatio) }}
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'tr' + i">
              {{ percentify(monthlySummary(i)?.timeRatio) }}
            </td>
          </tr>
          <tr class="table-section-row">
            <td class="blue-cell has-text-right">
              {{
                $tf(
                  "supportProjectPage.overview.table.row.costProgressBar|Költés"
                )
              }}
            </td>
            <td>
              <b-progress
                :value="projectDetails.ytdSummary?.all?.costBudgetRatio"
                :max="1"
                type="is-warning"
                size="is-small"
              />
            </td>
            <td>
              <b-progress
                :value="projectDetails.yearlySummary?.all?.costBudgetRatio"
                :max="1"
                type="is-warning"
                size="is-small"
              />
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'spp' + i">
              <b-progress
                :value="monthlySummary(i)?.costBudgetRatio"
                :max="1"
                type="is-warning"
                size="is-small"
              />
            </td>
          </tr>
          <tr class="table-section-row">
            <td class="blue-cell has-text-right">
              {{
                $tf(
                  "supportProjectPage.overview.table.row.timeProgressBar|Költés"
                )
              }}
            </td>
            <td>
              <b-progress
                :value="projectDetails.ytdSummary?.all?.timeRatio"
                :max="1"
                type="is-info"
                size="is-small"
              />
            </td>
            <td>
              <b-progress
                :value="projectDetails.yearlySummary?.all?.timeRatio"
                :max="1"
                type="is-info"
                size="is-small"
              />
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'tpp' + i">
              <b-progress
                :value="monthlySummary(i)?.timeRatio"
                :max="1"
                type="is-info"
                size="is-small"
              />
            </td>
          </tr>
          <tr class="table-section-row">
            <td class="blue-cell has-text-left">
              {{
                $tf("supportProjectPage.overview.table.row.riskKPI|Risk KPI")
              }}
            </td>
            <td>
              <risk-kpi-tag :value="projectDetails.ytdSummary?.all?.riskKPI" />
            </td>
            <td>
              <risk-kpi-tag
                :value="projectDetails.yearlySummary?.all?.riskKPI"
              />
            </td>
            <td></td>
            <td v-for="{ i } in activeMonths" :key="'rk' + i">
              <risk-kpi-tag :value="monthlySummary(i)?.riskKPI" />
            </td>
          </tr>
          <tr
            class="table-section-row"
            :class="{ 'last-row': projectDetails.internal }"
          >
            <td class="blue-cell has-text-left">
              {{
                $tf(
                  "supportProjectPage.overview.table.row.difference|Túlköltés / megtakarítás összesen"
                )
              }}
            </td>
            <td
              class="has-text-right"
              :class="
                monthlySummary(now.getMonth() + 1)?.difference <= 0
                  ? 'has-data-green'
                  : 'has-data-red'
              "
            >
              <div>
                {{ moneyify(projectDetails.ytdSummary?.all?.difference) }}
              </div>
              <div>
                {{
                  percentify(projectDetails.ytdSummary?.all?.differenceRatio)
                }}
              </div>
            </td>
            <td
              class="has-text-right"
              :class="
                projectDetails.yearlySummary?.all?.difference <= 0
                  ? 'has-data-green'
                  : 'has-data-red'
              "
            >
              <div>
                {{ moneyify(projectDetails.yearlySummary?.all?.difference) }}
              </div>
              <div>
                {{
                  percentify(projectDetails.yearlySummary?.all?.differenceRatio)
                }}
              </div>
            </td>
            <td></td>
            <td
              class="has-text-right"
              v-for="{ i } in activeMonths"
              :key="'oss' + i"
              :class="
                monthlySummary(i)?.difference <= 0
                  ? 'has-data-green'
                  : 'has-data-red'
              "
            >
              <div>
                {{ moneyify(monthlySummary(i)?.difference) }}
              </div>
              <div>
                {{ percentify(monthlySummary(i)?.differenceRatio) }}
              </div>
            </td>
          </tr>
          <template v-if="!projectDetails.internal">
            <tr class="table-section-row double-first">
              <td class="blue-cell has-text-left" rowspan="2">
                <div class="columns">
                  <div class="column">
                    {{
                      $tf(
                        "supportProjectPage.overview.table.row.plannedProfit|Tervezett profit összesen"
                      )
                    }}
                  </div>
                  <div
                    class="column pl-0 my-auto is-narrow"
                    v-if="hasAnyActivity"
                  >
                    <a
                      @click="plannedProfitExpanded = !plannedProfitExpanded"
                      class="expand-toggle"
                      ><b-icon
                        :icon="
                          plannedProfitExpanded ? 'chevron-up' : 'chevron-down'
                        "
                      ></b-icon>
                    </a>
                  </div>
                </div>
              </td>
              <td>
                {{ moneyify(projectDetails.ytdSummary?.all?.plannedProfit) }}
              </td>
              <td>
                {{ moneyify(projectDetails.yearlySummary?.all?.plannedProfit) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'ppa' + i">
                {{ moneyify(monthlySummary(i)?.plannedProfit) }}
              </td>
            </tr>
            <tr class="table-section-row double-second">
              <td>
                {{
                  percentify(projectDetails.ytdSummary?.all?.plannedProfitRatio)
                }}
              </td>
              <td>
                {{
                  percentify(
                    projectDetails.yearlySummary?.all?.plannedProfitRatio
                  )
                }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'ppp' + i">
                {{ percentify(monthlySummary(i)?.plannedProfitRatio) }}
              </td>
            </tr>
            <tbody v-if="plannedProfitExpanded">
              <tr class="table-section-row double-first" v-if="hasFixes">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                  }}
                </td>
                <td>
                  {{ moneyify(projectDetails.ytdSummary?.fix?.plannedProfit) }}
                </td>
                <td>
                  {{
                    moneyify(projectDetails.yearlySummary?.fix?.plannedProfit)
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pppl' + i">
                  {{ moneyify(monthlyFixSummary(i)?.plannedProfit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasFixes">
                <td>
                  {{
                    percentify(
                      projectDetails.ytdSummary?.fix?.plannedProfitRatio
                    )
                  }}
                </td>
                <td>
                  {{
                    percentify(
                      projectDetails.yearlySummary?.fix?.plannedProfitRatio
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pppf' + i">
                  {{ percentify(monthlyFixSummary(i)?.plannedProfitRatio) }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasLockups">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                  }}
                </td>
                <td>
                  {{
                    moneyify(projectDetails.ytdSummary?.lockup?.plannedProfit)
                  }}
                </td>
                <td>
                  {{
                    moneyify(
                      projectDetails.yearlySummary?.lockup?.plannedProfit
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pppl' + i">
                  {{ moneyify(monthlyLockupSummary(i)?.plannedProfit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasLockups">
                <td>
                  {{
                    percentify(
                      projectDetails.ytdSummary?.lockup?.plannedProfitRatio
                    )
                  }}
                </td>
                <td>
                  {{
                    percentify(
                      projectDetails.yearlySummary?.lockup?.plannedProfitRatio
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pppf' + i">
                  {{ percentify(monthlyLockupSummary(i)?.plannedProfitRatio) }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasBillables">
                <td rowspan="2">Elszámolható feladatok:</td>
                <td>
                  {{
                    moneyify(projectDetails.ytdSummary?.billable?.plannedProfit)
                  }}
                </td>
                <td>
                  {{
                    moneyify(
                      projectDetails.yearlySummary?.billable?.plannedProfit
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'ppab' + i">
                  {{ moneyify(monthlyBillableSummary(i)?.plannedProfit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasBillables">
                <td>
                  {{
                    percentify(
                      projectDetails.ytdSummary?.billable?.plannedProfitRatio
                    )
                  }}
                </td>
                <td>
                  {{
                    percentify(
                      projectDetails.yearlySummary?.billable?.plannedProfitRatio
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pppf' + i">
                  {{
                    percentify(monthlyBillableSummary(i)?.plannedProfitRatio)
                  }}
                </td>
              </tr>
            </tbody>
            <tr class="table-section-row double-first">
              <td class="blue-cell has-text-left" rowspan="2">
                <div class="columns">
                  <div class="column">
                    {{
                      $tf(
                        "supportProjectPage.overview.table.row.profit|Tény profit összesen"
                      )
                    }}
                  </div>
                  <div
                    class="column pl-0 my-auto is-narrow"
                    v-if="hasAnyActivity"
                  >
                    <a
                      @click="profitExpanded = !profitExpanded"
                      class="expand-toggle"
                      ><b-icon
                        :icon="profitExpanded ? 'chevron-up' : 'chevron-down'"
                      ></b-icon>
                    </a>
                  </div>
                </div>
              </td>
              <td>{{ moneyify(projectDetails.ytdSummary?.all?.profit) }}</td>
              <td>{{ moneyify(projectDetails.yearlySummary?.all?.profit) }}</td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'pa' + i">
                {{ moneyify(monthlySummary(i)?.profit) }}
              </td>
            </tr>
            <tr class="table-section-row double-second">
              <td>
                {{ percentify(projectDetails.ytdSummary?.all?.profitRatio) }}
              </td>
              <td>
                {{ percentify(projectDetails.yearlySummary?.all?.profitRatio) }}
              </td>
              <td></td>
              <td v-for="{ i } in activeMonths" :key="'pp' + i">
                {{ percentify(monthlySummary(i)?.profitRatio) }}
              </td>
            </tr>
            <tbody v-if="profitExpanded">
              <tr class="table-section-row double-first" v-if="hasFixes">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                  }}
                </td>
                <td>{{ moneyify(projectDetails.ytdSummary?.fix?.profit) }}</td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.fix?.profit) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'paf' + i">
                  {{ moneyify(monthlyFixSummary(i)?.profit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasFixes">
                <td>
                  {{ percentify(projectDetails.ytdSummary?.fix?.profitRatio) }}
                </td>
                <td>
                  {{
                    percentify(projectDetails.yearlySummary?.fix?.profitRatio)
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'ppf' + i">
                  {{ percentify(monthlyFixSummary(i)?.profitRatio) }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasLockups">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                  }}
                </td>
                <td>
                  {{ moneyify(projectDetails.ytdSummary?.lockup?.profit) }}
                </td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.lockup?.profit) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pal' + i">
                  {{ moneyify(monthlyLockupSummary(i)?.profit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasLockups">
                <td>
                  {{
                    percentify(projectDetails.ytdSummary?.lockup?.profitRatio)
                  }}
                </td>
                <td>
                  {{
                    percentify(
                      projectDetails.yearlySummary?.lockup?.profitRatio
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'ppl' + i">
                  {{ percentify(monthlyLockupSummary(i)?.profitRatio) }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasBillables">
                <td rowspan="2">Elszámolható feladatok:</td>
                <td>
                  {{ moneyify(projectDetails.ytdSummary?.billable?.profit) }}
                </td>
                <td>
                  {{ moneyify(projectDetails.yearlySummary?.billable?.profit) }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'pab' + i">
                  {{ moneyify(monthlyBillableSummary(i)?.profit) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasBillables">
                <td>
                  {{
                    percentify(projectDetails.ytdSummary?.billable?.profitRatio)
                  }}
                </td>
                <td>
                  {{
                    percentify(
                      projectDetails.yearlySummary?.billable?.profitRatio
                    )
                  }}
                </td>
                <td></td>
                <td v-for="{ i } in activeMonths" :key="'ppb' + i">
                  {{ percentify(monthlyBillableSummary(i)?.profitRatio) }}
                </td>
              </tr>
            </tbody>
            <tr class="table-section-row double-first last-row">
              <td class="blue-cell has-text-left" rowspan="2">
                <div class="columns">
                  <div class="column">
                    {{
                      $tf(
                        "supportProjectPage.overview.table.row.profitDifference|Profit eltérés"
                      )
                    }}
                  </div>
                  <div
                    class="column pl-0 my-auto is-narrow"
                    v-if="hasAnyActivity"
                  >
                    <a
                      @click="
                        profitDifferenceExpanded = !profitDifferenceExpanded
                      "
                      class="expand-toggle"
                      ><b-icon
                        :icon="
                          profitDifferenceExpanded
                            ? 'chevron-up'
                            : 'chevron-down'
                        "
                      ></b-icon>
                    </a>
                  </div>
                </div>
              </td>
              <td
                :class="
                  projectDetails.ytdSummary?.all?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
              >
                {{ moneyify(projectDetails.ytdSummary?.all?.profitDifference) }}
              </td>
              <td
                :class="
                  projectDetails.yearlySummary?.all?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
              >
                {{
                  moneyify(projectDetails.yearlySummary?.all?.profitDifference)
                }}
              </td>
              <td></td>
              <td
                :class="
                  monthlySummary(i)?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
                v-for="{ i } in activeMonths"
                :key="'pda' + i"
              >
                {{ moneyify(monthlySummary(i)?.profitDifference) }}
              </td>
            </tr>
            <tr class="table-section-row double-second last-row">
              <td
                :class="
                  projectDetails.ytdSummary?.all?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
              >
                {{
                  percentify(
                    projectDetails.ytdSummary?.all?.profitDifferenceRatio
                  )
                }}
              </td>
              <td
                :class="
                  projectDetails.yearlySummary?.all?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
              >
                {{
                  percentify(
                    projectDetails.yearlySummary?.all?.profitDifferenceRatio
                  )
                }}
              </td>
              <td></td>
              <td
                :class="
                  monthlySummary(i)?.profitDifference >= 0
                    ? 'has-data-green'
                    : 'has-data-red'
                "
                v-for="{ i } in activeMonths"
                :key="'pdp' + i"
              >
                {{
                  percentify(
                    monthlySummary(now.getMonth() + 1)?.profitDifferenceRatio
                  )
                }}
              </td>
            </tr>
            <tbody v-if="profitDifferenceExpanded">
              <tr class="table-section-row double-first" v-if="hasFixes">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.fix|Fix árazású")
                  }}
                </td>
                <td
                  :class="
                    projectDetails.ytdSummary?.fix?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(projectDetails.ytdSummary?.fix?.profitDifference)
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.fix?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(
                      projectDetails.yearlySummary?.fix?.profitDifference
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyFixSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdaf' + i"
                >
                  {{ moneyify(monthlyFixSummary(i)?.profitDifference) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasFixes">
                <td
                  :class="
                    projectDetails.ytdSummary?.fix?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.ytdSummary?.fix?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.fix?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.yearlySummary?.fix?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyFixSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdpf' + i"
                >
                  {{ percentify(monthlyFixSummary(i)?.profitDifferenceRatio) }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasLockups">
                <td rowspan="2">
                  {{
                    $tf("supportProjectPage.overview.table.row.lockup|Lekötés")
                  }}
                </td>
                <td
                  :class="
                    projectDetails.ytdSummary?.lockup?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(
                      projectDetails.ytdSummary?.lockup?.profitDifference
                    )
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.lockup?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(
                      projectDetails.yearlySummary?.lockup?.profitDifference
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyLockupSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdal' + i"
                >
                  {{ moneyify(monthlyLockupSummary(i)?.profitDifference) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasLockups">
                <td
                  :class="
                    projectDetails.ytdSummary?.lockup?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.ytdSummary?.lockup?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.lockup?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.yearlySummary?.lockup
                        ?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyLockupSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdpl' + i"
                >
                  {{
                    percentify(monthlyLockupSummary(i)?.profitDifferenceRatio)
                  }}
                </td>
              </tr>
              <tr class="table-section-row double-first" v-if="hasBillables">
                <td rowspan="2">Elszámolható feladatok:</td>
                <td
                  :class="
                    projectDetails.ytdSummary?.billable?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(
                      projectDetails.ytdSummary?.billable?.profitDifference
                    )
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.billable?.profitDifference >=
                    0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    moneyify(
                      projectDetails.yearlySummary?.billable?.profitDifference
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyBillableSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdab' + i"
                >
                  {{ moneyify(monthlyBillableSummary(i)?.profitDifference) }}
                </td>
              </tr>
              <tr class="table-section-row double-second" v-if="hasBillables">
                <td
                  :class="
                    projectDetails.ytdSummary?.billable?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.ytdSummary?.billable?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td
                  :class="
                    projectDetails.yearlySummary?.billable?.profitDifference >=
                    0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                >
                  {{
                    percentify(
                      projectDetails.yearlySummary?.billable
                        ?.profitDifferenceRatio
                    )
                  }}
                </td>
                <td></td>
                <td
                  :class="
                    monthlyBillableSummary(i)?.profitDifference >= 0
                      ? 'has-data-green'
                      : 'has-data-red'
                  "
                  v-for="{ i } in activeMonths"
                  :key="'pdpb' + i"
                >
                  {{
                    percentify(monthlyBillableSummary(i)?.profitDifferenceRatio)
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
      <div class="card mt-4">
        <div class="card-content">
          <p class="has-text-grey has-font-weight-500">
            {{
              $tf(
                "supportProjectPage.charts.budgetDifference|Büdzsé tartás - eltérés vs. büdzsé"
              )
            }}
          </p>
          <p class="title">
            {{ moneyify(projectDetails.yearlySummary?.all?.difference) }}
          </p>
          <div>
            <apex-chart
              :options="{
                chart: {
                  height: 350,
                  stacked: false,
                },
                xaxis: {
                  categories: [
                    'Jan',
                    'Feb',
                    'Márc',
                    'Ápr',
                    'Máj',
                    'Jún',
                    'Júl',
                    'Aug',
                    'Szept',
                    'Okt',
                    'Nov',
                    'Dec',
                  ],
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [3],
                  offsetY: -10,
                  background: {
                    enabled: false,
                  },
                  style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto, Arial, sans-serif',
                    fontWeight: 'bold',
                  },
                },
                colors: ['#F765A3', '#165BAA', '#A155B9', '#E697FF'],
                stroke: {
                  width: [0, 0, 0, 3],
                },
              }"
              :series="[
                {
                  name: this.$tf(
                    'supportProjectPage.overview.table.row.fix|Fix árazású'
                  ),
                  type: 'column',
                  data:
                    projectDetails.monthlySummaries?.map((s) =>
                      percentify(s.fix?.differenceRatio)
                    ) ?? [],
                },
                {
                  name: this.$tf(
                    'supportProjectPage.overview.table.row.lockup|Lekötés'
                  ),
                  type: 'column',
                  data:
                    projectDetails.monthlySummaries?.map((s) =>
                      percentify(s.lockup?.differenceRatio)
                    ) ?? [],
                },
                // {
                //   name: 'Elszámolható feladatok',
                //   type: 'column',
                //   data:
                //     projectDetails.monthlySummaries?.map((s) =>
                //       percentify(s.billable?.differenceRatio)
                //     ) ?? [],
                // },
                {
                  name: 'Total',
                  type: 'line',
                  data:
                    projectDetails.monthlySummaries?.map((s) =>
                      percentify(s.all?.differenceRatio)
                    ) ?? [],
                },
              ]"
            />
          </div>
        </div>
      </div>
      <div v-if="!projectDetails.internal" class="card mt-4">
        <div class="card-content">
          <p class="has-text-grey has-font-weight-500">
            {{ $tf("supportProjectPage.charts.profit|Profit") }}
          </p>
          <p class="title">
            {{ moneyify(projectDetails.yearlySummary?.all?.profit) }}
          </p>
          <div>
            <apex-chart
              :options="{
                chart: {
                  height: 350,
                  type: 'line',
                  stacked: true,
                },
                xaxis: {
                  categories: [
                    'Jan',
                    'Feb',
                    'Márc',
                    'Ápr',
                    'Máj',
                    'Jún',
                    'Júl',
                    'Aug',
                    'Szept',
                    'Okt',
                    'Nov',
                    'Dec',
                  ],
                },
                dataLabels: {
                  enabled: true,
                  enabledOnSeries: [3],
                  offsetY: -10,
                  background: {
                    enabled: false,
                  },
                  style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto, Arial, sans-serif',
                    fontWeight: 'bold',
                  },
                },
                colors: ['#F765A3', '#165BAA', '#A155B9', '#E697FF'],
                stroke: {
                  width: [0, 0, 0, 3],
                },
              }"
              :series="[
                {
                  name: this.$tf(
                    'supportProjectPage.overview.table.row.fix|Fix árazású'
                  ),
                  type: 'column',
                  data:
                    projectDetails.monthlySummaries?.map(
                      (s) => s.fix?.profit / 1000
                    ) ?? [],
                },
                {
                  name: this.$tf(
                    'supportProjectPage.overview.table.row.lockup|Lekötés'
                  ),
                  type: 'column',
                  data:
                    projectDetails.monthlySummaries?.map(
                      (s) => s.lockup?.profit / 1000
                    ) ?? [],
                },
                // {
                //   name: 'Elszámolható feladatok',
                //   type: 'column',
                //   data:
                //     projectDetails.monthlySummaries?.map(
                //       (s) => s.billable?.profit / 1000
                //     ) ?? [],
                // },
                {
                  name: 'Total',
                  type: 'line',
                  data:
                    projectDetails.monthlySummaries?.map(
                      (s) => s.all?.profit / 1000
                    ) ?? [],
                },
              ]"
            />
          </div>
        </div>
      </div>

      <div class="mt-5" style="overflow-x: auto; padding-bottom: 24px">
        <h3 class="subtitle">
          {{ $tf("supportProjectPage.employees.title|Dolgozói lebontás") }}
        </h3>
        <table class="support-status-table">
          <tr class="task-table-header-row">
            <th style="width: 128px">
              {{ $tf("supportProjectPage.employees.table.header.name|Név") }}
            </th>
            <th style="width: 128px" class="has-text-centered">
              {{ $tf("supportProjectPage.employees.table.header.rate|Órabér") }}
            </th>
            <th style="width: 16px"></th>
            <th style="width: 128px"></th>
            <th style="width: 144px" class="table-header-month-cell">
              {{
                $tf(
                  "supportProjectPage.employees.table.header.entireYear|Teljes év"
                )
              }}
            </th>
            <th style="width: 16px"></th>
            <th
              style="width: 144px"
              class="table-header-month-cell"
              v-for="{ i } in activeMonths"
              :key="'aa' + i"
            >
              {{
                $tf(SHORT_MONTH_NAMES[i - 1])
                  .charAt(0)
                  .toUpperCase() + $tf(SHORT_MONTH_NAMES[i - 1]).slice(1)
              }}
            </th>
          </tr>
          <template v-for="(employee, i) in employees" :key="i">
            <tr class="task-table-section-row double-first">
              <td class="task-cell" rowspan="2">{{ employee.name }}</td>
              <td rowspan="2">{{ `${Math.round(employee.avgRate)} Ft/h` }}</td>
              <td class="divider-cell"></td>
              <td class="task-cell blue-cell">
                {{
                  $tf(
                    "supportProjectPage.employees.table.row.loggedTime|Logolt idő"
                  )
                }}
              </td>
              <td>
                {{ `${roundToTwoDecimals(employee.timespent / 3600)} óra` }}
              </td>
              <td class="divider-cell"></td>
              <td v-for="{ i } in activeMonths" :key="`1-${i}`">
                {{
                  `${roundToTwoDecimals(
                    employee?.segments[i - 1]?.timespent / 3600
                  )} óra`
                }}
              </td>
            </tr>
            <tr class="task-table-section-row double-second">
              <td class="divider-cell"></td>
              <td class="task-cell blue-cell">
                {{ $tf("supportProjectPage.employees.table.row.cost|Költés") }}
              </td>
              <td>{{ moneyify(employee.moneyspent) }}</td>
              <td class="divider-cell"></td>
              <td v-for="{ i } in activeMonths" :key="`2-${i}`">
                {{ moneyify(employee?.segments[i - 1]?.moneyspent) }}
              </td>
            </tr>
          </template>
        </table>
      </div>

      <b-collapse
        v-model="ticketListOpened"
        aria-id="supportTicketList"
        animation="slide"
        class="card mt-5 mb-5"
      >
        <template v-slot:trigger>
          <div
            class="card-header"
            role="button"
            aria-controls="supportTicketList"
          >
            <div class="is-flex is-align-items-center pl-2">
              <h3 class="subtitle">
                <span>{{
                  $tf("supportProjectPage.issues.title|Jegyek listája")
                }}</span>
              </h3>
            </div>
            <div class="ml-auto">
              <a class="card-header-icon">
                <b-icon
                  :icon="ticketListOpened ? 'chevron-up' : 'chevron-down'"
                >
                </b-icon>
              </a>
            </div>
          </div>
        </template>
        <div class="mt-5" style="overflow-x: auto; padding-bottom: 24px">
          <table class="support-status-table">
            <tr class="task-table-header-row">
              <th style="width: 272px">
                <div class="columns">
                  <div class="column">
                    {{
                      $tf(
                        "supportProjectPage.issues.table.header.name|Tevékenység"
                      )
                    }}
                  </div>
                  <div class="column">
                    <b-dropdown
                      aria-role="list"
                      v-model="selectedTaskType"
                      class="is-fullwidth"
                    >
                      <template #trigger="{ active }">
                        <b-button
                          :label="selectedTaskType"
                          type="is-primary"
                          :icon-right="active ? 'chevron-up' : 'chevron-down'"
                        />
                      </template>

                      <b-dropdown-item aria-role="listitem" value="ALL">
                        {{
                          $tf(
                            "supportProjectPage.issues.table.dropdown.option.all|Összes"
                          )
                        }}
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem" value="FIX">
                        {{
                          $tf(
                            "supportProjectPage.issues.table.dropdown.option.fix|Fix árazású"
                          )
                        }}
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem" value="LOCKUP">
                        {{
                          $tf(
                            "supportProjectPage.issues.table.dropdown.option.lockup|Lekötés"
                          )
                        }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </th>
              <th style="width: 128px"></th>
              <th style="width: 16px"></th>
              <th style="width: 128px"></th>
              <th
                style="width: 144px"
                class="table-header-month-cell"
                v-for="{ i } in activeMonths"
                :key="'aa' + i"
              >
                {{
                  $tf(SHORT_MONTH_NAMES[i - 1])
                    .charAt(0)
                    .toUpperCase() + $tf(SHORT_MONTH_NAMES[i - 1]).slice(1)
                }}
              </th>
            </tr>
            <template
              v-for="issue in projectDetails.issues"
              :key="`issue-${issue.id}`"
            >
              <tr class="task-table-section-row double-first">
                <td class="task-cell" rowspan="2">{{ issue.identifier }}</td>
                <td rowspan="2">{{ issue.name }}</td>
                <td class="divider-cell"></td>
                <td class="task-cell blue-cell">
                  {{
                    $tf(
                      "supportProjectPage.issues.table.row.loggedSeconds|Logolt idő"
                    )
                  }}
                </td>
                <td
                  v-for="{ i } in activeMonths"
                  :key="`issue-${issue.id}-1-${i}`"
                >
                  {{
                    issue.months[i - 1].loggedSeconds > 0
                      ? secondsToJiraTime(issue.months[i - 1].loggedSeconds)
                      : "-"
                  }}
                </td>
              </tr>
              <tr class="task-table-section-row double-second">
                <td class="divider-cell"></td>
                <td class="task-cell blue-cell">
                  {{ $tf("supportProjectPage.issues.table.row.cost|Költés") }}
                </td>
                <td
                  v-for="{ i } in activeMonths"
                  :key="`issue-${issue.id}-2-${i}`"
                >
                  {{ moneyify(issue.months[i - 1].cost) }}
                </td>
              </tr>
            </template>
          </table>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import { PROJECT_TYPES, SHORT_MONTH_NAMES } from "@/utils/const";
import {
  moneyify,
  percentify,
  roundToTwoDecimals,
  secondsToJiraTime,
} from "@/utils/util";
import { mapGetters } from "vuex";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";
import { isEmpty } from "lodash";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";

export default {
  name: "SupportProjectStatus",
  components: { LoadingSkeleton, RiskKpiTag },
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "enterprise_core/fetchIssues",
      this.projectDetails.id
    );
    await this.$store.dispatch("enterprise_core/fetchProjectEmployees", {
      type: "support",
      identifier: this.projectDetails.identifier,
      year: this.selectedYear,
    });
  },
  data() {
    return {
      now: new Date(),
      selectedYear: new Date().getFullYear(),
      selectedTab: null,
      includedTypes: ["FIX", "LOCKUP", "BILLABLE"],
      incomeExpanded: false,
      budgetExpanded: false,
      costExpanded: false,
      costRatioExpanded: false,
      plannedProfitExpanded: false,
      profitExpanded: false,
      profitDifferenceExpanded: false,
      selectedTaskType: "ALL",
      SHORT_MONTH_NAMES,
      ticketListOpened: false,
    };
  },
  watch: {
    async selectedYear(val) {
      this.$emit("updateYear", val);
      await this.$store.dispatch("enterprise_core/fetchProjectEmployees", {
        identifier: this.projectDetails.identifier,
        type: PROJECT_TYPES.SUPPORT,
        year: val,
      });
    },
    includedTypes(val) {
      this.$emit("updateTypes", val);
    },
  },
  computed: {
    ...mapGetters({
      issues: "enterprise_core/issues",
      projectEmployees: "enterprise_core/projectEmployees",
    }),
    employees() {
      return this.projectEmployees(this.projectDetails.identifier)?.[
        this.selectedYear
      ];
    },
    years() {
      let years = [];
      let year = new Date().getFullYear() + 1;
      while (year > 2019) {
        years.push(year);
        year--;
      }
      return years;
    },
    issuesMappedByActivities() {
      return (
        this.issues(this.projectDetails?.id)?.filter(
          (issue) =>
            this.selectedTaskType === "ALL" ||
            (
              this.projectDetails?.activities
                ?.filter((activity) => activity.type === this.selectedTaskType)
                ?.map((activity) => activity.id) ?? []
            ).includes(issue.tigId)
        ) ?? []
      );
    },

    hasAnyActivity() {
      return this.hasFixes || this.hasLockups || this.hasBillables;
    },

    hasFixes() {
      return !isEmpty(this.projectDetails?.fixes ?? []);
    },
    hasLockups() {
      return !isEmpty(this.projectDetails?.lockups ?? []);
    },
    hasBillables() {
      return !isEmpty(this.projectDetails?.billables ?? []);
    },
    activeMonths() {
      return this.projectDetails?.monthlySummaries?.map((summary) => {
        return { i: summary.month };
      });
    },
    greenBubblesCount() {
      if (this.selectedYear < this.now.getFullYear()) return 12;
      if (this.selectedYear > this.now.getFullYear()) return 0;
      return Math.max(
        this.now.getMonth() - (11 - this.activeMonths.length + 1),
        0
      );
    },
    blueBubblesCount() {
      if (this.selectedYear < this.now.getFullYear()) return 0;
      if (this.selectedYear > this.now.getFullYear()) return 12;
      return 11 - this.now.getMonth();
    },
  },
  methods: {
    secondsToJiraTime,
    roundToTwoDecimals,
    percentify,
    moneyify,

    monthlySummary(month) {
      return this.projectDetails?.monthlySummaries?.find(
        (monthlySummary) => monthlySummary.month === month
      )?.all;
    },
    monthlyFixSummary(month) {
      return this.projectDetails?.monthlySummaries?.find(
        (monthlySummary) => monthlySummary.month === month
      )?.fix;
    },
    monthlyLockupSummary(month) {
      return this.projectDetails?.monthlySummaries?.find(
        (monthlySummary) => monthlySummary.month === month
      )?.lockup;
    },
    monthlyBillableSummary(month) {
      return this.projectDetails?.monthlySummaries?.find(
        (monthlySummary) => monthlySummary.month === month
      )?.billable;
    },
    dayOfYear(date) {
      return Math.floor(
        (date - new Date(this.now.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.month-type-bubble {
  border-radius: 8px;
  text-align: center;
  padding: 4px;
  width: 120px;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.bubble-green {
  background: #48c78e;
}

.bubble-red {
  background: #f14668;
}

.bubble-blue {
  background: #4e75d9;
}

.support-status-table {
  table-layout: fixed;
  border: none;
}

.support-status-table td,
.support-status-table th {
  border: none;
  vertical-align: middle;
}

.table-header-row .table-header-month-cell {
  background: $grey-light;
  color: $custom-dark-blue;
  text-align: center;
}

.table-header-row > td:not(:nth-child(5)),
.table-header-row > th:not(:nth-child(5)),
.task-table-header-row {
  padding: 6px 4px;
  font-size: 0.9rem;
  width: 144px;
}

.table-header-row > th:nth-child(5) {
  border-radius: 8px 0 0 8px;
}

.table-section-row > td:not(:first-child),
.table-section-row.double-second {
  background: $grey-light;
}

.first-row > td:first-child {
  border-top-left-radius: 8px;
}

.last-row > td:first-child {
  border-bottom-left-radius: 8px;
}

.table-section-row:not(.double-second) > td:first-child {
  text-align: center;
  font-weight: 500;
  border-right: 2px solid $grey-lighter;
}

.first-row > td:nth-child(4) {
  // border-top-right-radius: 8px;
}

.last > td:nth-child(4) {
  // border-bottom-right-radius: 8px;
}

.table-section-row:not(.double-first) > td:not(:first-child),
.table-section-row.double-second > td,
.task-table-section-row.double-second > td:not(.divider-cell),
.task-table-section-row.double-first > td:first-child,
.task-table-section-row.double-first > td:nth-child(2) {
  border-bottom: 2px solid $grey-lighter;
}

.table-section-row
  > td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(
    :nth-child(4)
  ) {
  text-align: right;
  font-weight: 500;
  color: $custom-dark-blue;
}

.separator-row > td {
  height: 16px;
}

.blue-cell {
  background: #2f2e6b !important;
  color: white;
}
.expand-toggle {
  color: white;
}
.expand-toggle:hover {
  color: $primary;
}
r .has-data-green {
  color: #48c78e !important;
}
.has-data-red {
  color: #f14668 !important;
}
.task-table-section-row.double-first > td:not(.divider-cell),
.task-table-section-row.double-second > td:not(.divider-cell) {
  background: $grey-light;
}
.task-cell {
  font-weight: 500;
  text-align: left;
}
.task-table-section-row:not(.double-second) > td:nth-child(2),
.task-table-section-row:not(.double-second) > td:nth-child(4),
.task-table-section-row:not(.double-first) > td:nth-child(2) {
  border-right: 2px solid $grey-lighter;
}

.subtitle {
  margin-bottom: 0 !important;
}

.table-header-month-cell {
  width: 144px !important;
}
</style>
