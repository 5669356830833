<template>
  <section class="section">
    <div class="container">
      <h1 v-if="myTimeSheet" class="title">
        {{ $tf("employeeTime.title.own|Timesheetjeim") }}
      </h1>
      <h1 v-if="!myTimeSheet" class="title">
        {{ $tf("employeeTime.title.general|Timesheetek") }}
      </h1>

      <b-field
        :label="$tf('employeeTime.picker.label|Munkatárs')"
        label-position="on-border"
        class="is-long mb-2"
        v-if="!myTimeSheet"
      >
        <b-autocomplete
          rounded
          v-model="name"
          :data="filteredEmployees"
          :placeholder="
            $tf('employeeTime.picker.placeholder|pl.: Elektrom Ágnes')
          "
          icon="search"
          clearable
          field="name"
          @select="(option) => (selectedUser = option)"
        >
          <template #empty>{{
            $tf("employeeTime.picker.empty|Nincs találat...")
          }}</template>
        </b-autocomplete>
      </b-field>

      <div class="is-flex">
        <b-field class="mt-4">
          <select-with-buttons
            v-model="selectedDate"
            :options="yearsAndMonths"
            dropdown
            value-key="value"
            name-key="name"
            reversed
          />
        </b-field>

        <div
          class="is-flex has-gap-2 ml-auto mt-auto mb-auto is-align-items-center"
        >
          <b-button
            type="is-primary"
            class="mr-3"
            @click="onLogClick"
            :disabled="!selectedUser"
            icon-left="plus"
            v-if="myTimeSheet && (selfTrackingMode || !!apiKey)"
          >
            {{ $tf("employeeTime.button.logTime|Munkaidő rögzítése") }}
          </b-button>
          <p
            class="has-text-red mb-0"
            v-if="myTimeSheet && !apiKey && !selfTrackingMode"
          >
            {{
              $tf("employeeTime.warning.noApiKey|Nincs megadott API kulcsod!")
            }}
          </p>
          <b-button
            type="is-primary"
            @click="onExportClick"
            :loading="isExportInProgress"
            :disabled="!selectedUser"
            icon-left="download"
          >
            {{ $tf("employeeTime.button.export|Exportálás") }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="container" v-if="!selectedUser">
      <h2 class="subtitle">
        {{
          $tf(
            "employeeTime.selectEmployee.first|Lekéréshez válassz egy kollégát"
          )
        }}
      </h2>
    </div>

    <div v-if="timelog && expected">
      <div class="container summary">
        <span class="summary-item">
          <b-icon icon="sum" type="is-title" />
          <label class="ml-1 mr-1">{{
            $tf("employeeTime.summary.balance|Egyenleg:")
          }}</label>
          <label
            class="has-font-weight-500"
            :class="{ 'has-text-red': summedTime < 0 }"
          >
            {{ secondToShortWorkDayFormat(summedTime, true) }}</label
          >
        </span>

        <span class="summary-item" v-if="shiftOvertime">
          <b-icon icon="time-plus" type="is-purple" />
          <label class="ml-1 mr-1">{{
            $tf("employeeTime.summary.shift|Lecsúsztatás:")
          }}</label>
          <label class="has-font-weight-500">{{
            secondToShortWorkDayFormat(shiftOvertime)
          }}</label>
        </span>

        <span class="summary-item" v-if="cashOvertime">
          <b-icon icon="coins" class="has-text-purple mr-1" size="is-small" />
          <label class="ml-1 mr-1">{{
            $tf("employeeTime.summary.payout|Kifizetett túlóra:")
          }}</label>
          <label class="has-font-weight-500">{{
            secondToShortWorkDayFormat(cashOvertime)
          }}</label>
        </span>

        <span class="summary-item">
          <b-icon icon="time-arrow" type="is-title" />
          <label class="ml-1 mr-1">{{
            $tf("employeeTime.summary.loggedTime|Logolt idő:")
          }}</label>
          <label class="has-font-weight-500"
            >{{ secondToShortWorkDayFormat(totalTimespent) }} /
            {{ secondToShortWorkDayFormat(expectedTimespent) }}</label
          >
        </span>

        <span class="summary-item" v-if="!isAggregateType">
          <b-icon icon="wrench" type="is-title" />
          <label class="ml-1 mr-1">{{
            $tf("employeeTime.summary.daysWorked|Munkával töltött napok:")
          }}</label>
          <label class="has-font-weight-500">{{
            $tf(
              "employeeTime.summary.loggedTime.display|{worked} nap / {expected} nap",
              { worked: workedDays, expected: expectedDays }
            )
          }}</label>
        </span>

        <span class="summary-item has-text-red" v-if="untrackedTime">
          <b-icon icon="absence" type="is-danger" />
          <label class="ml-1 mr-1">{{
            $tf(
              "employeeTime.summary.untrackedTime|Beazonosítatlan projektre logolt idő:"
            )
          }}</label>
          <label class="has-font-weight-500">{{
            secondToShortWorkDayFormat(untrackedTime)
          }}</label>
        </span>
      </div>

      <div class="container">
        <div v-if="timelog && expected">
          <b-tabs
            type="is-toggle"
            class="timesheet-tab"
            v-model="activeTab"
            :animated="false"
          >
            <b-tab-item>
              <template #header>
                <b-icon icon="grid-2" size="is-small" class="mr-2" />
                {{ $tf("employeeTime.tabs.tiled|Csempés nézet") }}
              </template>
              <timesheet
                :year="selectedYearAndMonth.data.year"
                :month="selectedYearAndMonth.data.month - 1"
                :hours="calcTimesheetHours()"
                :specialDays="specialDays"
                :absenceRequests="absenceRequests"
                :expected="expected"
                :workingLocations="workingLocationsForMonth"
                :key="timelog.totalSeconds"
                @open="onOpen"
                class="mt-5"
              ></timesheet>
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <b-icon icon="columns" size="is-small" class="mr-2" />
                {{ $tf("employeeTime.tabs.columns|Oszlopdiagram") }}
              </template>
              <timesheet-bar-graph
                :timelogs="timelogWithAddedExpectedTimes.days"
                :key="timelog.totalSeconds"
              ></timesheet-bar-graph>
            </b-tab-item>
            <b-tab-item>
              <template #header>
                <b-icon icon="list" size="is-small" class="mr-2" />
                {{ $tf("employeeTime.tabs.list|Listás nézet") }}
              </template>

              <timesheet-rows
                :timelogs="timelog.days"
                :expected="expected"
                :my-time-sheet="myTimeSheet"
                :key="timelog.totalSeconds"
              />
            </b-tab-item>
            <!--            <b-tab-item disabled>-->
            <!--              <template #header>-->
            <!--                <b-icon icon="circle" size="is-small" class="mr-2" />-->
            <!--                Projektek-->
            <!--              </template>-->
            <!--            </b-tab-item>-->
          </b-tabs>
        </div>
      </div>
      <div v-if="!selfTrackingMode" class="container">
        <div class="has-text-centered">
          <p class="tag mb-4">
            {{
              $tf(
                "employeeTime.sync.syncedAt|Adatok szinkronizálva: {updatedAt}",
                { updatedAt: updatedAt }
              )
            }}
          </p>

          <br />

          <div class="columns">
            <div class="column">
              <b-button
                type="is-danger"
                @click="resyncSheets()"
                :loading="resyncing"
                >{{ $tf("employeeTime.sync.button|Frissítés") }}
              </b-button>
              <b-tooltip
                :triggers="['click']"
                :auto-close="['outside', 'escape']"
                class="ml-2"
                type="is-danger"
              >
                <template v-slot:content>
                  <div class="content tooltip">
                    <b-icon
                      icon="exclamation-triangle"
                      size="is-large"
                      class="untracked-triangle employee-time-tooltip-icon"
                    />
                    <p>
                      {{
                        $tf(
                          "employeeTime.sync.tooltip|Szinkronizálás indítása az időlogolásokat rögzítő rendszerrel, ez hosszabb időt is igénybe vehet."
                        )
                      }}
                    </p>
                  </div>
                </template>
                <b-icon icon="info-circle" />
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="loading">
      <div class="columns">
        <div class="column is-half">
          <h2 class="subtitle">
            {{
              $tf(
                "employeeTime.loading.progress|Adatok letöltése folyamatban..."
              )
            }}
          </h2>
        </div>
        <div class="column is-half"></div>
      </div>
    </div>
    <div class="container" v-if="!myTimeSheet && !(timelog && expected)">
      <div class="columns">
        <div class="column is-half">
          <div class="columns">
            <div class="column is-half">
              <b-field
                :label="$tf('employeeTime.refresh.yearInput|Év')"
                label-position="on-border"
              >
                <b-input type="input" v-model="refresh.year"></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                :label="$tf('employeeTime.refresh.monthInput|Hónap')"
                label-position="on-border"
              >
                <b-select v-model="refresh.month">
                  <option :value="1">{{ $tf("month.january|Január") }}</option>
                  <option :value="2">
                    {{ $tf("month.february|Február") }}
                  </option>
                  <option :value="3">{{ $tf("month.march|Március") }}</option>
                  <option :value="4">{{ $tf("month.april|Április") }}</option>
                  <option :value="5">{{ $tf("month.may|Május") }}</option>
                  <option :value="6">{{ $tf("month.june|Június") }}</option>
                  <option :value="7">{{ $tf("month.july|Július") }}</option>
                  <option :value="8">
                    {{ $tf("month.august|Augusztus") }}
                  </option>
                  <option :value="9">
                    {{ $tf("month.september|Szeptember") }}
                  </option>
                  <option :value="10">
                    {{ $tf("month.october|Október") }}
                  </option>
                  <option :value="11">
                    {{ $tf("month.november|November") }}
                  </option>
                  <option :value="12">
                    {{ $tf("month.december|December") }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <b-button
            type="is-primary"
            class="mr-3"
            @click="refreshCommand()"
            :disabled="!refresh.year || !refresh.month"
            icon-left="plus"
          >
            {{
              $tf(
                "employeeTime.refresh.startRefresh|Kijelölt hónap teljes frissítése"
              )
            }}
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Timesheet from "../../../components/timesheet/Timesheet";
import SelectWithButtons from "../../../components/module/input/SelectWithButtons";
import eventBus, { EVENTS } from "../../../utils/eventBus";
import TimesheetDetailsModal from "../../../components/timesheet/details/TimesheetDetailsModal";
import {
  deepCopy,
  formatDate,
  formatDateTime,
  getLowercaseMonthName,
  secondToShortWorkDayFormat,
} from "@/utils/util";
import TimesheetRows from "../../../components/timesheet/tab/row/TimesheetRows";
import FileSaver from "file-saver";
import TimesheetBarGraph from "@/components/timesheet/tab/graph/TimesheetBarGraphTab";
import LoadingMixin from "@/mixins/LoadingMixin";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";

export default {
  name: "EmployeeTime",
  components: {
    TimesheetBarGraph,
    TimesheetRows,
    SelectWithButtons,
    Timesheet,
  },
  props: {
    myTimeSheet: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.$store.dispatch("api_key/list");
  },
  computed: {
    yearsAndMonths() {
      const startDate = new Date(2022, 0, 1);
      const now = new Date();
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);

      let yearsAndMonths = [];
      let currDate = startDate;
      while (currDate <= endDate) {
        yearsAndMonths.push({
          value: `${currDate.getFullYear()}-${currDate.getMonth()}`,
          data: {
            year: currDate.getFullYear(),
            month: currDate.getMonth() + 1,
          },
          name: `${currDate.getFullYear()} ${getLowercaseMonthName(
            currDate.getMonth()
          )}`,
        });
        currDate.setMonth(currDate.getMonth() + 1);
      }
      return yearsAndMonths.reverse();
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name) return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            ?.toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    timelogWithAddedExpectedTimes() {
      const currDate = new Date();
      const now = new Date();
      currDate.setFullYear(
        this.selectedYearAndMonth.data.year,
        this.selectedYearAndMonth.data.month - 1,
        1
      );
      const cTimelog = deepCopy(this.timelog);
      const cExpected = deepCopy(this.expected);
      cTimelog.days.forEach((log) => {
        log.dailyTotalSeconds /= 3600;
        log.dailyTotalOvertimeSeconds /= 3600;
        return log;
      });
      while (
        currDate.getMonth() === this.selectedYearAndMonth.data.month - 1 &&
        currDate <= now
      ) {
        const formattedDate = formatDate(currDate);
        const foundLog = cTimelog.days.find((log) => log.day === formattedDate);
        const foundExpected = cExpected.days.find(
          (expected) => expected.day === formattedDate
        );
        const expectedHour = !!foundExpected ? foundExpected.hours : 0;
        if (!!foundLog) {
          cTimelog.days[cTimelog.days.indexOf(foundLog)].expected =
            expectedHour;
        } else {
          //if(expectedHour !== 0)
          cTimelog.days.push({
            day: formattedDate,
            dailyTimespent: 0,
            expected: expectedHour,
          });
        }
        currDate.setDate(currDate.getDate() + 1);
      }
      cTimelog.days.sort((o1, o2) => new Date(o1.day) - new Date(o2.day));
      return cTimelog;
    },
    timelog() {
      if (this.myTimeSheet) {
        return this.worklog(
          this.selectedYearAndMonth.data.year,
          this.selectedYearAndMonth.data.month
        );
      } else if (this.selectedUser) {
        return this.worklog(
          this.selectedUser.id,
          this.selectedYearAndMonth.data.year,
          this.selectedYearAndMonth.data.month
        );
      } else {
        return {};
      }
    },
    expected() {
      if (this.myTimeSheet) {
        return this.required(
          this.selectedYearAndMonth.data.year,
          this.selectedYearAndMonth.data.month
        );
      } else {
        return this.required(
          this.selectedUser?.id,
          this.selectedYearAndMonth.data.year,
          this.selectedYearAndMonth.data.month
        );
      }
    },
    specialDays() {
      return this.specialDaysGetter(this.selectedYearAndMonth.data.year);
    },
    absenceRequests() {
      return this.getAbsenceRequests;
    },
    summedTime() {
      return this.totalTimespent - this.expectedTimespent;
    },
    workedDays() {
      return this.timelog ? this.timelog.days.length : 0;
    },
    isAggregateType() {
      return (
        this.selectedUser && this.selectedUser.workSchedule === "AGGREGATE"
      );
    },
    expectedDays() {
      return this.expected ? this.expected.neededDays : 0;
    },
    shiftOvertime() {
      return this.timelog ? this.timelog.totalShiftOvertime : 0;
    },
    cashOvertime() {
      return this.timelog ? this.timelog.totalCashOvertime : 0;
    },
    totalTimespent() {
      return this.timelog ? this.timelog.totalSeconds : 0;
    },
    expectedTimespent() {
      if (this.isAggregateType) {
        return this.selectedUser.aggregateHours * 3600;
      }
      return this.expected ? this.expected.total * 3600 : 0;
    },
    untrackedTime() {
      return this.timelog ? this.timelog.totalUntrackedTimespent : 0;
    },
    updatedAt() {
      return this.timelog && this.timelog.updatedAt
        ? formatDateTime(this.timelog.updatedAt)
        : "-";
    },
    actMonthFirstDay() {
      return new Date(
        this.selectedYearAndMonth.data.year,
        this.selectedYearAndMonth.data.month - 1,
        1
      );
    },
    actMonthLastDay() {
      return new Date(
        this.selectedYearAndMonth.data.year,
        this.selectedYearAndMonth.data.month,
        0
      );
    },
    workingLocationsForMonth() {
      let workingLocations = [];
      if (this.workSchedules && this.workSchedules.length > 0) {
        this.workSchedules.forEach((week) => {
          week.hours.forEach((hour) => {
            if (hour.place && hour.actualDate) {
              workingLocations.push({
                day: hour.actualDate,
                location: hour.place.identifier,
              });
            }
          });
        });
      }
      return workingLocations;
    },
    selfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      profile: "census_user/profile",
      employees: "employee/timesheetWorkers",
      specialDaysGetter: "specialday/specialDaysAll",
      apiKeys: "api_key/list",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      userId: "session/userId",
      workSchedules: "work_schedule/ownWorkSchedules",
    }),
    ...mapState({
      getAbsenceRequests(_state, getters) {
        return getters[
          this.myTimeSheet
            ? "absence_request/mineAbsenceRequests"
            : "absence_request/userAbsenceRequests"
        ];
      },
      worklog(_state, getters) {
        return getters[
          this.myTimeSheet ? "worklog/myWorklog" : "worklog/worklogOf"
        ];
      },
      required(_state, getters) {
        return getters[
          this.myTimeSheet ? "worklog/myExpected" : "worklog/expectedOf"
        ];
      },
    }),
    apiKey() {
      return this.apiKeys.find((it) => it.type === this.issueTrackingSystem);
    },
    selectedDate: {
      get() {
        return {
          value: this.selectedYearAndMonth.value,
          name: this.selectedYearAndMonth.name,
        };
      },
      set(val) {
        this.selectedYearAndMonth = this.yearsAndMonths.find(
          (yearAndMonth) => yearAndMonth.value === val
        );
      },
    },
  },
  data: function () {
    return {
      formatDateTime: formatDateTime,
      loading: false,
      name: "",
      selectedUser: null,
      selectedYearAndMonth: {
        value: `${new Date().getFullYear()}-${new Date().getMonth()}`,
        data: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
        },
        name: `${new Date().getFullYear()} ${getLowercaseMonthName(
          new Date().getMonth()
        )}`,
      },
      resyncing: false,
      activeTab: 0,
      isExportInProgress: false,
      refresh: {
        year: new Date().getFullYear(),
        month: null,
      },
    };
  },
  methods: {
    secondToShortWorkDayFormat,
    refreshCommand() {
      this.$store.dispatch("enterprise_core/monthRefresh", this.refresh);
    },
    onLogClick() {
      // let self = this;
      // if (this.selfTrackingMode) {
      //   this.$buefy.modal.open({
      //     parent: this,
      //     component: SelfTrackingLogModal,
      //     hasModalCard: true,
      //     trapFocus: true,
      //     width: "100%",
      //     onCancel: () => {
      //       self.fetchWorkLogs();
      //     },
      //   });
      // } else {
      //   this.$buefy.modal.open({
      //     parent: this,
      //     component: EmployeeLogBarModal,
      //     hasModalCard: true,
      //     trapFocus: true,
      //     width: "100%",
      //     onCancel: () => {
      //       self.fetchWorkLogs();
      //     },
      //   });
      // }
      this.$router.push("/logging");
    },
    async onExportClick() {
      this.isExportInProgress = true;
      try {
        let data;
        if (this.myTimeSheet) {
          data = await this.$store.dispatch("worklog/exportMine", {
            year: this.selectedYearAndMonth.data.year,
            month: this.selectedYearAndMonth.data.month,
          });
        } else {
          data = await this.$store.dispatch("worklog/export", {
            user: this.selectedUser.id,
            year: this.selectedYearAndMonth.data.year,
            month: this.selectedYearAndMonth.data.month,
          });
        }

        FileSaver.saveAs(
          data,
          `${this.selectedUser.name}_${this.selectedYearAndMonth.data.year}_${this.selectedYearAndMonth.data.month}.xlsx`
        );
      } finally {
        this.isExportInProgress = false;
      }
    },
    resyncSheets: async function () {
      this.resyncing = true;
      let query = {
        user: this.selectedUser.id,
        year: this.selectedYearAndMonth.data.year,
        month: this.selectedYearAndMonth.data.month,
      };
      if (this.myTimeSheet) {
        await this.$store.dispatch("worklog/refreshMineSpecific", query);
        await this.fetchWorkLogs(true);
      } else {
        await this.$store.dispatch("worklog/refreshSpecific", query);
        await this.fetchWorkLogs(true);
      }
      this.resyncing = false;
    },
    calcTimesheetHours: function () {
      if (this.timelog) {
        let dayArray = this.timelog.days;
        let hours = {};
        for (let d in dayArray) {
          let day = dayArray[d];
          hours[day.day] = {};

          let hour = hours[day.day];
          hour.dailyTotalSeconds = day.dailyTotalSeconds;
          hour.dailyTotalOvertimeSeconds = day.dailyTotalOvertimeSeconds;
          hour.tracked = day.dailyTimespent;
          hour.untracked = day.dailyUntrackedTimespent;
          hour.conflicted = day.dailyConflictedTimespent;
          hour.hasCashOvertime = day.dailyCashOvertime > 0;
          hour.hasShiftOvertime = day.dailyShiftOvertime > 0;
          hour.dailyStartTime = day.dailyStartTime;
          hour.dailyEndTime = day.dailyEndTime;
          hour.color = day.color;
          hour.topProjectName = day.topProjectName;
          hour.projectCount = day.projectCount;
        }
        return hours;
      }
      return {};
    },
    async fetchWorkLogs(force) {
      this.loading = true;
      let query = {
        user: this.selectedUser.id,
        year: this.selectedYearAndMonth.data.year,
        month: this.selectedYearAndMonth.data.month,
      };
      if (this.myTimeSheet) {
        await this.$store.dispatch("worklog/fetchMine", {
          params: query,
          force,
        });
        await this.$store.dispatch("worklog/fetchMyExpected", query);
      } else if (this.selectedUser) {
        await this.$store.dispatch("worklog/fetch", {
          params: query,
          force,
        });
        await this.$store.dispatch("worklog/fetchExpected", query);
      }
      this.loading = false;
    },
    async fetchSpecialDays() {
      await this.$store.dispatch("specialday/fetchSpecialDaysAll", {
        year: this.selectedYearAndMonth.data.year,
      });
    },
    async fetchAbsenceRequests() {
      const searchParams = new URLSearchParams();
      searchParams.append("unpaged", "true");
      const requestParams = {
        params: searchParams,
      };
      if (this.myTimeSheet) {
        await this.$store.dispatch("absence_request/getMine", {
          params: requestParams,
        });
      } else if (this.selectedUser) {
        await this.$store.dispatch("absence_request/getUserAbsenceRequests", {
          user: this.selectedUser.id,
          params: requestParams,
        });
      }
    },
    async fetchWorkSchedules() {
      if (this.myTimeSheet) {
        await this.$store.dispatch("work_schedule/getWorkSchedulesForMe");
      } else if (this.selectedUser) {
        await this.$store.dispatch(
          "work_schedule/getWorkSchedulesForUser",
          this.selectedUser.id
        );
      }
    },
    dateToISOWeek(dt) {
      let tdt = new Date(dt.valueOf());
      let dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      let firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    onOpen(date) {
      let timesheetDetails = null;

      let filteredTimelog = this.timelog.days.filter((day) => day.day === date);
      if (filteredTimelog.length > 0) {
        timesheetDetails = filteredTimelog.shift();
      }

      this.$buefy.modal.open({
        parent: this,
        component: TimesheetDetailsModal,
        props: {
          data: timesheetDetails,
          expected: this.expected,
          day: date,
          myTimeSheet: this.myTimeSheet,
        },
        hasModalCard: true,
        trapFocus: true,
        canCancel: ["outside", "escape"],
        customClass: "timesheet-details-modal",
      });
    },
  },
  async created() {
    this.doStartLoading();
    await this.fetchSpecialDays();
    await this.fetchWorkSchedules();
    if (this.myTimeSheet) {
      await this.$store.dispatch("census_user/fetchProfile");
      this.selectedUser = this.profile;
    } else {
      await this.$store.dispatch("employee/fetchWorkersForTimeSheet");
    }

    eventBus.$on(EVENTS.OVERTIME_REQUESTED, () => this.fetchWorkLogs(true));

    this.doFinishLoading();
  },
  unmounted() {
    eventBus.$off(EVENTS.OVERTIME_REQUESTED);
  },
  watch: {
    "selectedYearAndMonth.data.year": {
      handler: async function handle() {
        await this.fetchSpecialDays();
        await this.fetchWorkLogs();
        await this.fetchWorkSchedules();
      },
      deep: true,
    },
    "selectedYearAndMonth.data.month": {
      handler: async function handle() {
        await this.fetchWorkLogs();
        await this.fetchWorkSchedules();
      },
      deep: true,
    },
    async selectedUser(newUser) {
      if (newUser) {
        await this.fetchWorkLogs();
        await this.fetchAbsenceRequests();
        await this.fetchWorkSchedules();
      }
    },
    timelog() {
      this.calcTimesheetHours();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors";

.summary {
  color: $custom-dark-blue;
  display: flex;
  flex-wrap: wrap;
  line-height: 2;

  .summary-item:not(:last-child) {
    margin-right: 1.75rem;
  }
}

.employee-time-tooltip-icon {
  color: var(--white) !important;
  transform: scale(2);
}
</style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.timesheet-tab {
  .tab-content {
    padding: 0 !important;
  }
}
</style>
