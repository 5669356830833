<template>
  <section v-if="!loading">
    <div class="container ralloc-container">
      <p class="ralloc-container-title">
        {{
          $tf(
            "rallocPage.projectAllocationsAndUtilization|Projektek allokációi, utilizáltságuk"
          )
        }}
      </p>
      <div class="is-flex is-align-items-center has-gap-2 mb-3">
        <div class="is-flex-4">
          <b-field
            :label="$tf('resource.utilization.filter.project|Projekt')"
            label-position="on-border"
            expanded
          >
            <multiselect-dropdown
              v-model="selectedProjects"
              :items="activeProjects"
              append-to-body
              identifier-field="id"
              name-field="name"
              use-tf
              expanded
              searchable
              clearable
              scrollable
              max-height="300"
              icon="circle-xmark"
              :disabled-function="isProjectSelectionDisabled"
              @iconRightClick="filters.selectedProjects = []"
              @update:modelValue="updateSelectedProjects"
            >
              <template #empty>{{
                $tf("resource.utilization.filter.empty|Nincs találat")
              }}</template>
            </multiselect-dropdown>
          </b-field>
        </div>
        <div class="is-flex-3">
          <b-field
            :label="$tf('resource.utilization.filter.manager|Projektmenedzser')"
            label-position="on-border"
          >
            <multiselect-dropdown
              v-model="filters.selectedEmployees"
              :items="leads"
              append-to-body
              identifier-field="id"
              name-field="name"
              use-tf
              expanded
              searchable
              clearable
              scrollable
              max-height="300"
              icon="circle-xmark"
              @iconRightClick="filters.selectedEmployees = []"
            />
          </b-field>
        </div>
      </div>
      <div class="is-flex is-align-items-center has-gap-2">
        <div class="is-flex-4">
          <b-field
            :label="$tf('resource.utilization.numberOfWeels|Hetek száma')"
            label-position="on-border"
          >
            <b-select v-model="display.numberOfWeeks">
              <option :value="4">
                {{
                  $tf("resource.utilization.numberOfWeeks|{number} hét", {
                    number: 4,
                  })
                }}
              </option>
              <option :value="8">
                {{
                  $tf("resource.utilization.numberOfWeeks|{number} hét", {
                    number: 8,
                  })
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div>
          <div class="ml-4 ralloc-container-icons">
            <b-icon
              class="cursor-pointer mr-2"
              icon="chevron-left"
              size="is-small"
              @click="minusWeek"
            />
            <span class="mr-2 ralloc-container-icons-weeks">
              {{
                `${displayedWeeks[0].week}-${
                  displayedWeeks[displayedWeeks.length - 1].week
                }${$tf("rallocPage.nthWeek|. hét")}`
              }}
            </span>
            <b-icon
              class="cursor-pointer"
              icon="chevron-right"
              size="is-small"
              @click="plusWeek"
            />
            <b-icon
              class="cursor-pointer ralloc-container-icons-undo"
              icon="undo"
              size="is-small"
              @click="resetWeek"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container utilization-projects">
      <div class="is-flex is-justify-content-end has-gap-6 mb-4">
        <div class="is-flex is-align-items-center has-gap-2">
          <div>
            <p :class="{ 'has-text-primary': display.time === 'DAYS' }">
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.time.days|Munkanapok"
                )
              }}
              <b-icon icon="sun"></b-icon>
            </p>
          </div>
          <div class="is-flex is-justify-content-center">
            <b-switch
              v-model="display.time"
              true-value="HOURS"
              false-value="DAYS"
              type="is-info"
              passive-type="is-info"
              class="mr-0"
            ></b-switch>
          </div>
          <div>
            <p :class="{ 'has-text-primary': display.time === 'HOURS' }">
              <b-icon icon="clock"></b-icon>
              {{
                $tf(
                  "rallocPage.employeeUtilization.display.time.hours|Munkaórák"
                )
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        id="utilization-table-container"
        class="utilization-table-container overflow-x-auto"
        ref="tableWrapper"
      >
        <b-table
          :data="extendedAssociations"
          class="ralloc-container-projects-table"
          v-if="extendedAssociations.length > 0"
          detailed
          detail-key="identifier"
          :opened-detailed="openDetails"
          :loading="fetchingAllocations"
        >
          <b-table-column
            field="name"
            :label="$tf('resource.utilization.projects.table.project|Projekt')"
            v-slot="{ row }"
          >
            <div
              @click="goToProject"
              class="utilization-top-row utilization-top-row-identifier"
            >
              {{ row.identifier }}
            </div>
            <div
              @click="goToProject"
              class="utilization-bottom-row utilization-bottom-row-name"
            >
              {{ row.name }}
            </div>
          </b-table-column>
          <b-table-column
            field="totalHours"
            :label="
              $tf('resource.utilization.projects.table.summary|Összefoglaló')
            "
            v-slot="{ row }"
            centered
          >
            <div class="utilization-top-row">
              <b-icon icon="sigma" />{{ totalAllocated(row) }}
            </div>
          </b-table-column>
          <b-table-column
            v-for="(week, i) in displayedWeeks"
            field="weekData.week"
            :key="i"
            centered
            :header-class="
              new Date(week.start).getWeek() === new Date().getWeek() &&
              new Date(week.start).getYear() === new Date().getYear()
                ? 'ralloc-container-header-now'
                : ''
            "
            cell-class="ralloc-container-column"
          >
            <template #header>
              <div>
                <b>
                  {{ `${week.week} ${$tf("rallocPage.nthWeek|. hét")}` }}
                </b>
                <span
                  v-if="
                    week.week === new Date().getWeek() &&
                    week.year === new Date().getUTCFullYear()
                  "
                  class="ralloc-container-header-this-week"
                >
                  {{ $tf("rallocPage.thisWeek| (e hét)") }}
                </span>
              </div>
              <div>
                {{ dateRange(week.year, week.week) }}
              </div>
            </template>
            <template v-slot="{ row }">
              <div
                :class="
                  new Date(week.start).getWeek() === new Date().getWeek() &&
                  new Date(week.start).getYear() === new Date().getYear()
                    ? 'ralloc-container-projects-table-current-week'
                    : ''
                "
              >
                <WeeklyProjectUtilization
                  :weekly="
                    findWeekData(
                      { year: week.year, week: week.week },
                      row.weekly
                    )
                  "
                  :future="week.start > new Date()"
                  :time-format="display.time"
                />
              </div>
            </template>
          </b-table-column>
          <template #detail="{ row }">
            <div class="users-details">
              <b-table :data="getAllocatedUsersForProject(row)">
                <b-table-column
                  v-slot="props"
                  centered
                  header-class="utilization-project-details-header"
                >
                  <user-info
                    display-mode="full"
                    :user="props.row"
                    class="utilization-project-details-user-info"
                  />
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  centered
                  header-class="utilization-project-details-header"
                >
                  <b-icon icon="sigma" />
                  {{
                    getTotalOfAllocatedUser(
                      props.row,
                      getWeeklyAllocationsForProject(row)
                    )
                  }}
                </b-table-column>
                <b-table-column
                  v-for="(week, index) in displayedWeeks"
                  :key="`${week}-${index}`"
                  v-slot="props"
                  centered
                  header-class="utilization-project-details-header"
                  cell-class="ralloc-container-column"
                >
                  <div
                    :class="
                      new Date(week.start).getWeek() === new Date().getWeek() &&
                      new Date(week.start).getYear() === new Date().getYear()
                        ? 'ralloc-container-projects-table-current-week-people'
                        : ''
                    "
                  >
                    <span>
                      {{
                        getWeeklyTotalOfAllocatedUser(
                          props.row,
                          getWeeklyAllocationsForProject(row)
                        )[week.week] ||
                        $tf("rallocPage.projectUtilization.noAllocation|N/A")
                      }}
                    </span>
                  </div>
                </b-table-column>
              </b-table>
            </div>
          </template>
        </b-table>
        <div v-else>
          <div class="ralloc-container-empty-list"></div>
          <div class="ralloc-container-empty-placeholder">
            {{
              $tf(
                "resource.utilization.projects.empty|A megjelenítéshez válassz legalább egy projektet!"
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import {
  workdayify,
  convertHoursToMWD,
  formatDate,
  addDay,
  subtractDay,
  percentify,
  getWeekDataBetween,
  localFetch,
} from "@/utils/util";
import WeeklyProjectUtilization from "@/components/ralloc/WeeklyProjectUtilization.vue";
import UserInfo from "../module/info/UserInfo.vue";
import moment from "moment/moment";

export default defineComponent({
  name: "UtilizationProjects",
  components: { UserInfo, WeeklyProjectUtilization, MultiselectDropdown },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.setDefaultDates();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      workdayify,
      formatDate,
      loading: true,
      filters: {
        selectedProjects: [],
        automaticallySelectedProjects: [],
        selectedEmployees: [],
      },
      tableWidth: 0,
      display: {
        time: "DAYS",
        numberOfWeeks: 4,
      },
      fromDate: undefined,
      toDate: undefined,
      openDetails: [],
      fetchingAllocations: false,
      storedLocale: localFetch("stored_locale") ?? "hu",
      noData: false,
    };
  },
  computed: {
    ...mapGetters({
      associations: "association/projectUtilizations",
      projects: "enterprise_core/limitedProjects",
      employees: "employee/employees",
      leads: "employee/leads",
    }),
    extendedAssociations() {
      let data = [];
      if (this.noData) return [];
      this.associations.forEach((project) => {
        let weeklyData = [];
        project.weeklyUtilizations.forEach((week) => {
          weeklyData.push({
            year: week.year,
            week: week.week,
            allocatedHours: week.allocatedHours,
          });
        });
        data.push({
          id: project.projectId,
          identifier: project.projectData.identifier,
          name: project.projectData.name,
          totalAllocated: project.totalHours,
          weekly: weeklyData,
        });
      });
      return data;
    },

    displayedWeeks() {
      if (!this.fromDate || !this.toDate) {
        return [];
      }

      return getWeekDataBetween(this.fromDate, this.toDate);
    },
    activeProjects() {
      return this.projects
        .filter(
          (project) =>
            !project.archived &&
            project.status !== "CANCELLED" &&
            project.status !== "DONE" &&
            project.status !== "CLOSED"
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    selectedProjects() {
      return this.filters.selectedProjects.concat(
        ...this.filters.automaticallySelectedProjects
      );
    },
  },
  watch: {
    selectedProjects: {
      handler: debounce(async function () {
        await this.fetchAssociations();
      }, 500),
      deep: true,
    },
    "filters.selectedEmployees": {
      handler: debounce(async function () {
        this.filters.automaticallySelectedProjects = [];
        if (this.filters.selectedEmployees) {
          this.filters.selectedEmployees.forEach((selectedEmployee) => {
            this.activeProjects.forEach((project) => {
              if (
                project.leadUserId === selectedEmployee &&
                !this.filters.automaticallySelectedProjects.includes(project.id)
              ) {
                this.filters.automaticallySelectedProjects.push(project.id);
              }
            });
          });
        }
      }, 500),
      deep: true,
    },
    "display.numberOfWeeks": {
      handler(val) {
        this.toDate = this.fromDate.addDays(val * 7 - 1);
        this.fetchAssociations();
      },
    },
  },
  methods: {
    percentify,
    convertHoursToMWD,
    addDay,
    subtractDay,
    plusWeek() {
      this.fromDate = new Date(this.addDay(this.fromDate, 7));
      this.toDate = new Date(this.addDay(this.toDate, 7));
      this.fetchAssociations();
    },
    minusWeek() {
      this.fromDate = new Date(this.subtractDay(this.fromDate, 7));
      this.toDate = new Date(this.subtractDay(this.toDate, 7));
      this.fetchAssociations();
    },
    resetWeek() {
      this.setDefaultDates();
      this.fetchAssociations(true);
    },
    setDefaultDates() {
      this.fromDate = new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() - 1)
        )
      );

      this.toDate = this.fromDate.addDays(this.display.numberOfWeeks * 7 - 1);
    },
    dateRange(year, weekNumber) {
      const formattedWeek = weekNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const startOfWeek = moment(`${year}W${formattedWeek}`)
        .startOf("isoWeek")
        .locale(this.storedLocale);
      const endOfWeek = moment(`${year}W${formattedWeek}`)
        .endOf("isoWeek")
        .locale(this.storedLocale);
      return `${startOfWeek.format("MMM D")}. - ${endOfWeek.format("MMM D")}.`;
    },
    findEmployee(employeeId) {
      return this.employees.find((employee) => employee.id === employeeId);
    },
    findWeekData({ year, week }, weeklyData) {
      for (let weekly of weeklyData) {
        if (weekly.year === year && weekly.week === week) {
          return weekly;
        }
      }
      return {
        noData: true,
      };
    },
    getMondayAndFriday(year, weekNumber) {
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay();
      const dayOffset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek;

      const monday = new Date(year, 0, 1 + dayOffset + (weekNumber - 1) * 7);
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4);

      return { monday, friday };
    },
    async fetchData() {
      await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
      await this.$store.dispatch("employee/fetchLeads");
    },
    async fetchAssociations(force = false) {
      this.fetchingAllocations = true;
      if (this.selectedProjects?.length) {
        this.noData = false;
        await this.$store.dispatch("association/getUtilizationForProjects", {
          projects: this.selectedProjects,
          from: formatDate(this.fromDate),
          to: formatDate(this.toDate),
          force,
        });
      } else {
        this.noData = true;
      }
      this.fetchingAllocations = false;
    },
    resizeHandler() {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
    goToProject(row) {
      if (row.type === "SUPPORT") {
        this.$router.push(`/projects/support/${row.identifier}`);
      } else if (row.type === "OUTSOURCE") {
        this.$router.push(`/projects/outsource/${row.identifier}`);
      } else {
        this.$router.push(`/projects/project/${row.identifier}`);
      }
    },
    getAssociationsForProject(row) {
      return this.associations?.find(
        (association) => association.projectData.identifier === row?.identifier
      );
    },
    getWeeklyAllocationsForProject(row) {
      return this.getAssociationsForProject(row)?.weeklyUtilizations;
    },
    getAllocatedUsersForProject(row) {
      const users = [];
      this.getWeeklyAllocationsForProject(row)?.forEach((week) => {
        week.associations.forEach((association) => {
          users.push(association.userId);
        });
      });
      return [...new Set(users)].map((userId) => {
        return this.employees.find((employee) => employee.id === userId);
      });
    },
    getWeeklyTotalOfAllocatedUser(user, weeklyAllocations) {
      const weeks = {};
      weeklyAllocations.forEach((week) => {
        let weekly = 0;
        week.associations.forEach((association) => {
          if (association.userId === user.id) {
            weekly = weekly + association.hours;
          }
        })
            weeks[week.week] = weekly
                ? this.display.time === "HOURS"
                    ? weekly + " h"
                    : workdayify(convertHoursToMWD(weekly))
                : this.$tf("rallocPage.projectUtilization.noAllocation|N/A");
      }
      );
      return weeks;
    },
    getTotalOfAllocatedUser(user, weeklyAllocations) {
      let total = 0;
      weeklyAllocations.forEach((week) =>
        week.associations.forEach((association) => {
          if (association.userId === user.id) {
            total = total + association.hours;
          }
        })
      );
      return total
        ? this.display.time === "HOURS"
          ? total + " h"
          : workdayify(convertHoursToMWD(total))
        : this.$tf("rallocPage.projectUtilization.noAllocation|N/A");
    },
    updateSelectedProjects(projects) {
      projects.forEach((project) => {
        if (!this.selectedProjects.includes(project)) {
          this.filters.selectedProjects.push(project);
        }
      });
    },
    isProjectSelectionDisabled(item) {
      return this.filters.automaticallySelectedProjects.includes(item.id);
    },
    totalAllocated(row) {
      return this.display.time === "HOURS"
        ? row.totalAllocated + " h"
        : workdayify(convertHoursToMWD(row.totalAllocated));
    },
    // extendedAssociationsGroupedByUsers(projectId) {
    //   let data = [];
    //   if (this.noData) return [];
    //
    //   const userGroups = {};
    //
    //   this.associations.forEach((project) => {
    //     if (project.projectId !== projectId) return;
    //     project.weeklyUtilizations.forEach((week) => {
    //       week.associations.forEach((assoc) => {
    //         const userId = assoc.userId;
    //
    //         if (!userGroups[userId]) {
    //           userGroups[userId] = {
    //             userId: userId,
    //             allocations: [],
    //           };
    //         }
    //
    //         let allocation = userGroups[userId].allocations.find(
    //           (a) => a.year === week.year && a.week === week.week
    //         );
    //
    //         if (!allocation) {
    //           allocation = {
    //             year: week.year,
    //             week: week.week,
    //             expectedHours: 40, // Default expected hours
    //             allocatedHours: 0,
    //             associations: [],
    //           };
    //           userGroups[userId].allocations.push(allocation);
    //         }
    //
    //         allocation.allocatedHours += assoc.hours;
    //         allocation.associations.push({
    //           projectId: project.projectId,
    //           hours: assoc.hours,
    //           priority: assoc.priority,
    //           type: project.projectData.type,
    //           projectData: project.projectData,
    //         });
    //       });
    //     });
    //   });
    //
    //   data = Object.values(userGroups);
    //
    //   return data;
    // },
  },
});
</script>

<style></style>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.utilization-projects {
  .table-wrapper:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.ralloc-container-column {
  width: 180px !important;
}

.borderless-cell {
  border: none !important;
}

.utilization-project {
  &-details {
    &-header {
      display: none !important;

      thead {
        display: none !important;
      }
    }

    &-user-info {
      max-width: 140px;
      text-align: left;

      figure {
        margin-right: 20px !important;
        margin-top: 6px !important;
      }
    }
  }
}

.ralloc-container {
  .content table td {
    border: 1px solid $grey-lightest !important;
  }

  &-projects-table {
    table {
      border-spacing: 0 8px !important;
      border-collapse: separate;
    }

    th {
      div {
        text-align: left !important;
        justify-content: left;
      }
    }

    td {
      border-top: 1px solid $grey-lightest !important;
      border-bottom: 1px solid $grey-lightest !important;
      color: $custom-dark-blue !important;
    }

    td:first-of-type {
      border-left: 1px solid $grey-lightest !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-of-type {
      border-right: 1px solid $grey-lightest !important;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &-current-week {
      background: $custom-lighter-blue;
      padding: 8px;
      border-radius: 12px;
    }

    &-current-week-people {
      background: $custom-lightest-blue;
      padding: 8px;
      border-radius: 12px;
    }

    .detail {
      border: none !important;
      border-radius: 0 !important;

      td[colspan="7"] {
        background: $white !important;
        border: none !important;
        border-radius: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;

        .detail-container {
          padding: 0 !important;
        }
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: $custom-dark-blue;
  }

  &-empty-list {
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: $grey-lightest;
    height: 30px;
  }

  &-empty-placeholder {
    height: 100%;
    width: 100%;
    padding: 21px;
    border-radius: 10px;
    border: 1px solid $grey-lightest;
    color: $grey-lighter;
    margin-top: 10px;
    text-align: center;
  }

  &-header-now {
    background: $grey-lighter !important;
  }

  &-icons {
    &-weeks {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $custom-dark-blue;
    }

    &-undo {
      margin-left: 16px;
      color: $blue;
    }
  }
}

.utilization-top-row {
  font-size: 14px;

  &-identifier {
    font-weight: 500;
    color: $custom-light-blue;
    cursor: pointer;
  }
}
.utilization-bottom-row {
  font-size: 14px;
  line-height: 16px;

  &-name {
    cursor: pointer;
  }
}
.util-actions-column {
  color: $blue;
}
</style>
