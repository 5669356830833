<template>
  <section class="section kontrolling">
    <div class="back ml-5 mb-3 has-font-weight-500">
      <RouterLink to="/projects/list">
        <div class="is-inline-flex is-align-items-center">
          <b-icon icon="chevron-left" />
          <p>{{ $tf("projectPage.backToList|Projektek") }}</p>
        </div>
      </RouterLink>
    </div>

    <div v-if="loading" style="height: 100vh">
      <div class="container">
        <p class="heading">
          {{ $tf("projectPage.loading|Adatok betöltése folyamatban...") }}
        </p>
        <b-skeleton></b-skeleton>
        <b-skeleton width="80%"></b-skeleton>
        <b-skeleton width="40%"></b-skeleton>
        <b-skeleton width="20%"></b-skeleton>
      </div>
    </div>
    <div v-else>
      <NormalProjectHeader
        :project-data="projectData"
        :project-details="projectDetails"
        @edit="activeTab = PROJECT_DETAILS_TABS.BASIC"
      />

      <div class="container">
        <b-tabs
          v-model="activeTab"
          type="is-toggle"
          expanded
          class="tab-without-padding"
        >
          <b-tab-item
            key="overview"
            :value="PROJECT_DETAILS_TABS.OVERVIEW"
            :label="$tf('projectPage.tab.overview|Áttekintés')"
            id="overview"
          >
            <project-status
              :project-data="projectData"
              :project-details="projectDetails"
              :bars="bars"
              :forecast-bars="forecastBars"
              :project-identifier="projectIdentifier"
              :employees="employees"
            />
            <!--            <project-forecast-->
            <!--              :project-details="projectDetails"-->
            <!--              :project-data="projectData"-->
            <!--              :tig-details="projectDetails"-->
            <!--            />-->

            <workers-list
              :project-data="projectData"
              :employee-data="projectEmployees(projectIdentifier)"
            />
            <normal-tig-status :project-details="projectDetails" />
            <!--
            <readiness-collapse :project-details="projectDetails" />
            <time-collapse :project-details="projectDetails" />
            <summary-collapse :project-details="projectDetails" />
            <budget-collapse :project-details="projectDetails" />
            -->
          </b-tab-item>
          <b-tab-item
            key="basic"
            :value="PROJECT_DETAILS_TABS.BASIC"
            :label="$tf('projectPage.tab.basic|Törzsadatok')"
            id="basic"
          >
            <project-edit
              :project-data="projectData"
              :project-details="projectDetails"
              @project-saved="(identifier) => onProjectSaved(identifier)"
              :employees="employees"
            ></project-edit>
            <ProjectCollapse id="journal">
              <template #header>
                <h3 class="title">
                  {{
                    $tf(
                      "projectPage.journal.title|Napló, események, változások"
                    )
                  }}
                  <b-tooltip
                    :auto-close="['outside', 'escape']"
                    :triggers="['click']"
                    type="is-info"
                  >
                    <template v-slot:content>
                      <div class="content tooltip">
                        <p>
                          {{
                            $tf(
                              "projectPage.journal.tooltip|Itt van lehetőség a projekt közben előforduló, a projekt lefolyását befolyásoló események dokumentálására. Futó projekt esetén csak ilyen bejegyzés keretében van lehetőség a projekt bizonyos elemein változtatni, pl.: vállalás, határidő, büdzsé, várható bevétel. A bejegyzések itt, illetve külön dashboardokon önmagukban is láthatóak."
                            )
                          }}
                        </p>
                      </div>
                    </template>
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </h3>
              </template>
              <journal-tab-item
                :project-data="projectData"
                :project-identifier="projectIdentifier"
                :project-details="projectDetails"
              />
            </ProjectCollapse>
          </b-tab-item>
          <b-tab-item
            key="resource"
            :value="PROJECT_DETAILS_TABS.RESOURCE"
            :label="$tf('projectPage.tab.resource|Erőforrások')"
            id="resource"
          >
            <ProjectResourceView
              :project-identifier="projectIdentifier"
              :project-type="PROJECT_TYPES.BASIC"
            />
          </b-tab-item>
          <b-tab-item
            key="tigs"
            :value="PROJECT_DETAILS_TABS.TIGS"
            :label="$tf('projectPage.tab.tigs|Mérföldkövek')"
            id="tigs"
          >
            <tig-list
              :project-data="projectData"
              :journal-entries="journalEntries"
              :project-identifier="projectIdentifier"
              @fetch="fetchData"
            />
          </b-tab-item>
          <b-tab-item
            key="invoices"
            :value="PROJECT_DETAILS_TABS.INVOICES"
            :label="
              $tf(
                projectData.internal
                  ? 'projectPage.tab.expenses|Kiadások'
                  : 'projectPage.tab.invoices|Számlák / Kiadások'
              )
            "
            id="invoices"
          >
            <invoice-tab-item
              :project-data="projectData"
              :type="PROJECT_TYPES.BASIC"
              :hide-invoices="projectData.internal"
              @fetch="fetchData"
            />
          </b-tab-item>
          <b-tab-item
            key="journal"
            :value="PROJECT_DETAILS_TABS.JOURNAL"
            :label="$tf('projectPage.tab.tasks|Jegyek')"
            id="journal"
          >
            <tasks-list
              :details="projectDetails"
              :project-identifier="projectIdentifier"
              :employees="employees"
              :issues="issues(projectData.id) ?? []"
              project-type="BASIC"
            />
          </b-tab-item>
        </b-tabs>
      </div>

      <ConfigurationWarnings
        :project-details="projectDetails"
        :project-data="projectData"
      ></ConfigurationWarnings>

      <sync-data
        v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
        :project-details="projectDetails"
        :project-id="projectData.id"
        :project-type="PROJECT_TYPES.BASIC"
        @refresh="fetchData(true)"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, formatDateTime, moneyify, numberify } from "@/utils/util";
import {
  ISSUE_TRACKING_SYSTEM,
  PROJECT_DETAILS_TABS,
  PROJECT_TYPES,
} from "@/utils/const";
import ProjectStatus from "@/components/enterprise/ProjectDetails/TabItems/NormalProjectStatus";
import JournalTabItem from "@/components/enterprise/ProjectDetails/TabItems/JournalTabItem";
import WorkersList from "@/components/enterprise/ProjectDetails/WorkersList";
import SyncData from "@/components/enterprise/ProjectDetails/SyncData";
import TigList from "@/components/enterprise/ProjectDetails/TabItems/TigList";
import BriefData from "@/components/enterprise/project/module/BriefData";
import InvoiceTabItem from "@/components/enterprise/ProjectDetails/TabItems/InvoiceTabItem.vue";
import ProjectEdit from "@/components/enterprise/project/form/ProjectEdit.vue";
import TasksList from "@/components/enterprise/ProjectDetails/Tasks/TasksList.vue";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse.vue";
import ConfigurationWarnings from "@/components/enterprise/project/module/ConfigurationWarnings.vue";
import NormalTigStatus from "@/components/enterprise/ProjectDetails/TabItems/NormalTigStatus.vue";
import ProjectResourceView from "@/views/ralloc/ProjectResourceView.vue";
import NormalProjectHeader from "@/components/enterprise/project/module/NormalProjectHeader.vue";

export default {
  name: "Enterprise-NormalProjectDetails",
  data() {
    return {
      ISSUE_TRACKING_SYSTEM,
      PROJECT_DETAILS_TABS,
      actualStatus: 0,
      moneyify,
      numberify,
      formatDate,
      formatDateTime,
      refreshing: false,
      loading: true,
      resyncing: false,
      newTig: false,
      newTigData: {},
      projectIdentifier: null,
      masks: {
        grossAmount: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      bars: {},
      forecastBars: {},
      name: "",
      newExpense: {
        identifier: null,
        summary: null,
        cost: null,
        date: new Date(),
      },
      newIncome: {
        identifier: null,
        summary: null,
        cost: null,
        date: new Date(),
      },
      newOverride: {
        userId: null,
        cost: null,
      },
      PROJECT_TYPES,
    };
  },
  components: {
    NormalProjectHeader,
    ProjectResourceView,
    NormalTigStatus,
    ConfigurationWarnings,
    ProjectCollapse,
    TasksList,
    ProjectEdit,
    InvoiceTabItem,
    SyncData,
    WorkersList,
    JournalTabItem,
    ProjectStatus,
    TigList,
  },
  computed: {
    projectDetails() {
      return this.projectDetailsGetter &&
        this.projectDetailsGetter(this.projectIdentifier)
        ? this.projectDetailsGetter(this.projectIdentifier)
        : {};
    },
    projectData() {
      return this.projectDataGetter &&
        this.projectDataGetter(this.projectIdentifier)
        ? this.projectDataGetter(this.projectIdentifier)
        : {};
    },
    tigKeys() {
      let tigKeys = [];
      for (let t in this.projectData.tigDatas) {
        tigKeys.push({
          key: this.projectData.tigDatas[t].key,
          name: this.projectData.tigDatas[t].name,
        });
      }
      return tigKeys;
    },
    totalSpent() {
      return (
        this.projectDetails.sumSpentBudget +
        numberify(this.projectData.expenseTotal)
      );
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name || !element.username) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    journalEntries() {
      return this.journalEntriesGetter(this.projectData.id);
    },
    activeTab: {
      get() {
        switch (this.$router.currentRoute.value.hash) {
          case PROJECT_DETAILS_TABS.HASH.BASIC:
            return PROJECT_DETAILS_TABS.BASIC;
          case PROJECT_DETAILS_TABS.HASH.RESOURCE:
            return PROJECT_DETAILS_TABS.RESOURCE;
          case PROJECT_DETAILS_TABS.HASH.TIGS:
            return PROJECT_DETAILS_TABS.TIGS;
          case PROJECT_DETAILS_TABS.HASH.INVOICES:
            return PROJECT_DETAILS_TABS.INVOICES;
          case PROJECT_DETAILS_TABS.HASH.JOURNAL:
            return PROJECT_DETAILS_TABS.JOURNAL;
          default:
            return PROJECT_DETAILS_TABS.OVERVIEW;
        }
      },
      set(val) {
        switch (val) {
          case PROJECT_DETAILS_TABS.OVERVIEW:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.OVERVIEW,
            });
            break;
          case PROJECT_DETAILS_TABS.BASIC:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.BASIC,
            });
            break;
          case PROJECT_DETAILS_TABS.RESOURCE:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.RESOURCE,
            });
            break;
          case PROJECT_DETAILS_TABS.TIGS:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.TIGS,
            });
            break;
          case PROJECT_DETAILS_TABS.EXPENSES:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.EXPENSES,
            });
            break;
          case PROJECT_DETAILS_TABS.INVOICES:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.INVOICES,
            });
            break;
          case PROJECT_DETAILS_TABS.JOURNAL:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.JOURNAL,
            });
            break;
          default:
            this.$router.push({
              hash: PROJECT_DETAILS_TABS.HASH.OVERVIEW,
            });
        }
      },
    },
    ...mapGetters({
      projectDataGetter: "enterprise_projects/projectData",
      projectDetailsGetter: "enterprise_core/projectDetails",
      journalEntriesGetter: "enterprise_journal/entries",
      employees: "employee/loggingEmployees",
      issues: "enterprise_core/issues",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
      projectEmployees: "enterprise_core/projectEmployees",
    }),
  },
  methods: {
    async onProjectSaved(identifier) {
      this.loading = true;
      if (identifier && identifier !== this.projectIdentifier) {
        await this.$router.push(`/projects/project/${identifier}#basic`);
      }
      await this.preloadData(true);
    },
    setupBars: function () {
      this.bars["project"] = {
        budget: this.projectDetails.budget,
        commitment: this.projectDetails.commitmentBudget,
        estimated: this.projectDetails.estimatedBudget,
        spent: this.projectDetails.sumSpentBudget,
        total:
          numberify(this.projectDetails.sumSpentBudget) +
          numberify(this.projectData.expenseTotal),
        forecast: this.projectDetails.forecastedFullBudgetCommitment,
      };
      this.bars["project"].max = Math.max(
        this.bars.project.budget,
        this.bars.project.commitment,
        this.bars.project.estimated,
        this.bars.project.spent,
        this.bars.project.total,
        this.bars.project.forecast
      );
      this.forecastBars["project"] = {
        remaining: this.projectDetails.budget - this.totalSpent,
        fullCommitment: this.projectDetails.remainingForecast,
        fullIssues:
          this.projectDetails.plannedProceedingForecast +
          this.projectDetails.plannedWaitingForecast,
      };
      this.forecastBars["project"].max = Math.max(
        this.forecastBars.project.remaining,
        this.forecastBars.project.fullCommitment,
        this.forecastBars.project.fullIssues
      );
      for (let t in this.projectDetails.tigDetails) {
        let tig = this.projectDetails.tigDetails[t];
        this.bars[tig.key] = {
          budget: tig.budget,
          commitment: tig.commitmentBudget,
          estimated: tig.estimatedBudget,
          spent: tig.sumSpentBudget,
          forecast: tig.forecastedFullBudgetCommitment,
        };
        this.bars[tig.key].max = Math.max(
          tig.budget,
          tig.commitmentBudget,
          tig.estimatedBudget,
          tig.sumSpentBudget,
          tig.forecastedFullBudgetCommitment
        );
        this.forecastBars[tig.key] = {
          remaining: tig.budget - tig.sumSpentBudget,
          fullCommitment: tig.remainingForecast,
          fullIssues:
            tig.plannedProceedingForecast + tig.plannedWaitingForecast,
        };
        this.forecastBars[tig.key].max = Math.max(
          this.forecastBars[tig.key].remaining,
          this.forecastBars[tig.key].fullCommitment,
          this.forecastBars[tig.key].fullIssues
        );
      }
    },
    preloadData: async function (force) {
      this.projectIdentifier = this.$route.params.id;
      await this.fetchData(force);
      this.setupBars();
      this.setActualStatus();
      this.loading = false;
    },
    fetchData: async function (force) {
      await this.$store.dispatch("enterprise_projects/fetchProjectData", {
        projectIdentifier: this.projectIdentifier,
        force,
      });

      let promises = [];
      promises.push(
        this.$store
          .dispatch("enterprise_core/fetchProjectDetails", {
            projectIdentifier: this.projectIdentifier,
            force,
          })
          .catch((reason) => {
            if (reason.response.status === 404)
              this.$router.push("../projects");
          })
      );
      promises.push(
        this.$store.dispatch("enterprise_core/fetchIssues", this.projectData.id)
      );
      promises.push(
        this.$store.dispatch("enterprise_journal/fetchEntries", {
          key: this.projectData.id,
          force,
        })
      );
      // promises.push(this.$store.dispatch("employee/fetchWorkers"));

      // promises.push(this.$store.dispatch("employee/fetch"));

      promises.push(this.$store.dispatch("project_like/fetchLikedProjects"));

      promises.push(
        this.$store.dispatch("enterprise_core/fetchProjectEmployees", {
          identifier: this.projectIdentifier,
          type: PROJECT_TYPES.BASIC,
        })
      );

      await Promise.all(promises);
      this.projectData.progressType = this.projectDetails.progressType;
    },
    setActualStatus() {
      if (this.projectData.status === "REQUEST") this.actualStatus = 0;
      else if (this.projectData.status === "APPROVED") this.actualStatus = 1;
      else if (this.projectData.status === "ONGOING") this.actualStatus = 2;
      else if (this.projectData.status === "DONE") this.actualStatus = 3;
    },
    resolveStatus(enu) {
      switch (enu) {
        case "REQUEST":
          return this.$tf("projectPage.status.request|Kérelem");
        case "CANCELLED":
          return this.$tf("projectPage.status.cancelled|Visszavonva");
        case "DENIED":
          return this.$tf("projectPage.status.denied|Elutasítva");
        case "APPROVED":
          return this.$tf("projectPage.status.approved|Elfogadva");
        case "ONGOING":
          return this.$tf("projectPage.status.ongoing|Folyamatban");
        case "DONE":
          return this.$tf("projectPage.status.done|Kész");
        case "CLOSED":
          return this.$tf("projectPage.status.closed|Lezárva");
      }
    },
  },
  async created() {
    await this.preloadData();
    if (this.projectDetails.archived) {
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$tf(
          "projectPage.archived.notification|Ez a projekt archiválva van!"
        ),
        position: "is-top",
        type: "is-danger",
      });
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

h3 {
  color: $custom-dark-blue !important;

  &.title {
    margin-bottom: 8px !important;
  }
}
.penzugy .progress-wrapper {
  margin-top: 5px;
  margin-bottom: 3px;
}

.button.is-money {
  background-color: $yellow;
  border-color: transparent;
  color: $white;
}
.button.is-percent {
  background-color: $grey-light;
  border-color: transparent;
  color: $grey-dark;
}
.button.is-time {
  background-color: $grey-light;
  border-color: transparent;
  color: $white;
}
.button.is-db {
  background-color: $grey-dark;
  border-color: transparent;
  color: $white;
}

.section.kontrolling {
  padding: 1rem 0 !important;
  background: $grey-lightest;
}

.section.kontrolling hr {
  border: 1px inset;
  background: none;
  margin: 1rem 0;
}

.section.kontrolling .tab-content {
  background: $white;
}

.bunch {
  background: $white;
  padding: 12px 15px 8px 15px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.bunch .input,
.bunch select,
.bunch .textarea,
.bunch .taginput-container {
  background: $white !important;
}

.bunch [disabled].input {
  border-color: $grey-lighter !important;
  background: $grey-lightest !important;
}

.image-addon img {
  margin-top: 4px;
  margin-left: 4px;
}

.status-change > ul,
li {
  list-style: none;
}

.content.tooltip {
  width: 400px;
}
.content.tooltip p,
.content.tooltip li {
  word-break: normal;
  white-space: normal;
}

.penzugy .progress-wrapper:not(:last-child) {
  margin-bottom: 0;
}

.employee-details .media {
  background: $white;
  padding: 10px;
  border-radius: 8px;
}

.field.is-floating-label .label:before {
  border-radius: 6px;
}

.project-key {
  &-input {
    input {
      margin-right: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  &-button {
    button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: 0 !important;
      margin-right: 1rem !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.back {
  .is-flex {
    font-size: 16px;
    font-weight: 500;
    color: $custom-dark-blue;
    margin-bottom: 12px;
    margin-left: 20px;
    width: fit-content;

    &:hover {
      color: $custom-light-blue !important;
    }

    gap: 12px;
  }
}
</style>
